<div
  [ngClass]="{ hidden: hideBanner() }"
  class="absolute bottom-0 z-50 flex w-full items-center gap-4 border-x-0 border-b-0 border-t border-solid border-purple-200 bg-purple-100 px-8 py-2.5 shadow-lg"
>
  <div class="flex grow flex-col gap-0.5">
    <span class="font-bold text-purple-500">
      {{ 'Please verify your account in order to receive notifications' | translate }}
    </span>
    <span
      class="text-grey-500"
      [innerHtml]="
        'An email was sent to [email], you can resend it using the button on the right. Easy peasy!'
          | translate
          | translationParams: { email: email$ | async | bold }
      "
    ></span>
  </div>

  <div class="flex items-center justify-start gap-2.5" [ngClass]="{ 'mr-16': isChatActive }">
    <a sb-button [color]="'purple'" [routerLink]="['my-account', 'login']">
      {{ 'Change mail' | translate }}
    </a>
    <button sb-button [color]="'outlinePurple'" (click)="resendEmail()">
      {{ 'Resend mail' | translate }}
    </button>

    <button sb-icon-button [color]="'purple'" (click)="onClose()">
      <sb-icon [name]="'times'"></sb-icon>
    </button>
  </div>
</div>
