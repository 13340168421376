export { applyAggregateErrorsToEvent } from './aggregate-errors.js';
export { getComponentName, getDomElement, getLocationHref, htmlTreeAsString } from './browser.js';
export { dsnFromString, dsnToString, makeDsn } from './dsn.js';
export { SentryError } from './error.js';
export { GLOBAL_OBJ, getGlobalObject, getGlobalSingleton } from './worldwide.js';
export { addInstrumentationHandler } from './instrument/index.js';
export { isDOMError, isDOMException, isElement, isError, isErrorEvent, isEvent, isInstanceOf, isNaN, isParameterizedString, isPlainObject, isPrimitive, isRegExp, isString, isSyntheticEvent, isThenable, isVueViewModel } from './is.js';
export { isBrowser } from './isBrowser.js';
export { CONSOLE_LEVELS, consoleSandbox, logger, originalConsoleMethods } from './logger.js';
export { memoBuilder } from './memo.js';
export { addContextToFrame, addExceptionMechanism, addExceptionTypeValue, arrayify, checkOrSetAlreadyCaught, getEventDescription, parseSemver, uuid4 } from './misc.js';
export { dynamicRequire, isNodeEnv, loadModule } from './node.js';
export { normalize, normalizeToSize, normalizeUrlToBase, walk } from './normalize.js';
export { addNonEnumerableProperty, convertToPlainObject, dropUndefinedKeys, extractExceptionKeysForMessage, fill, getOriginalFunction, markFunctionWrapped, objectify, urlEncode } from './object.js';
export { basename, dirname, isAbsolute, join, normalizePath, relative, resolve } from './path.js';
export { makePromiseBuffer } from './promisebuffer.js';
export { DEFAULT_USER_INCLUDES, addRequestDataToEvent, addRequestDataToTransaction, extractPathForTransaction, extractRequestData, winterCGHeadersToDict, winterCGRequestToRequestData } from './requestdata.js';
export { severityFromString, severityLevelFromString, validSeverityLevels } from './severity.js';
export { createStackParser, getFunctionName, nodeStackLineParser, stackParserFromStackParserOptions, stripSentryFramesAndReverse } from './stacktrace.js';
export { isMatchingPattern, safeJoin, snipLine, stringMatchesSomePattern, truncate } from './string.js';
export { isNativeFetch, supportsDOMError, supportsDOMException, supportsErrorEvent, supportsFetch, supportsNativeFetch, supportsReferrerPolicy, supportsReportingObserver } from './supports.js';
export { SyncPromise, rejectedSyncPromise, resolvedSyncPromise } from './syncpromise.js';
export { _browserPerformanceTimeOriginMode, browserPerformanceTimeOrigin, dateTimestampInSeconds, timestampInSeconds, timestampWithMs } from './time.js';
export { TRACEPARENT_REGEXP, extractTraceparentData, generateSentryTraceHeader, propagationContextFromHeaders, tracingContextFromHeaders } from './tracing.js';
export { getSDKSource, isBrowserBundle } from './env.js';
export { addItemToEnvelope, createAttachmentEnvelopeItem, createEnvelope, createEventEnvelopeHeaders, envelopeContainsItemType, envelopeItemTypeToDataCategory, forEachEnvelopeItem, getSdkMetadataForEnvelopeHeader, parseEnvelope, serializeEnvelope } from './envelope.js';
export { createClientReportEnvelope } from './clientreport.js';
export { DEFAULT_RETRY_AFTER, disabledUntil, isRateLimited, parseRetryAfterHeader, updateRateLimits } from './ratelimit.js';
export { BAGGAGE_HEADER_NAME, MAX_BAGGAGE_STRING_LENGTH, SENTRY_BAGGAGE_KEY_PREFIX, SENTRY_BAGGAGE_KEY_PREFIX_REGEX, baggageHeaderToDynamicSamplingContext, dynamicSamplingContextToSentryBaggageHeader } from './baggage.js';
export { getNumberOfUrlSegments, getSanitizedUrlString, parseUrl, stripUrlQueryAndFragment } from './url.js';
export { addOrUpdateIntegration } from './userIntegrations.js';
export { makeFifoCache } from './cache.js';
export { eventFromMessage, eventFromUnknownInput, exceptionFromError, parseStackFrames } from './eventbuilder.js';
export { callFrameToStackFrame, watchdogTimer } from './anr.js';
export { LRUMap } from './lru.js';
export { _asyncNullishCoalesce } from './buildPolyfills/_asyncNullishCoalesce.js';
export { _asyncOptionalChain } from './buildPolyfills/_asyncOptionalChain.js';
export { _asyncOptionalChainDelete } from './buildPolyfills/_asyncOptionalChainDelete.js';
export { _nullishCoalesce } from './buildPolyfills/_nullishCoalesce.js';
export { _optionalChain } from './buildPolyfills/_optionalChain.js';
export { _optionalChainDelete } from './buildPolyfills/_optionalChainDelete.js';
export { addConsoleInstrumentationHandler } from './instrument/console.js';
export { addClickKeypressInstrumentationHandler } from './instrument/dom.js';
export { SENTRY_XHR_DATA_KEY, addXhrInstrumentationHandler } from './instrument/xhr.js';
export { addFetchInstrumentationHandler } from './instrument/fetch.js';
export { addHistoryInstrumentationHandler } from './instrument/history.js';
export { addGlobalErrorInstrumentationHandler } from './instrument/globalError.js';
export { addGlobalUnhandledRejectionInstrumentationHandler } from './instrument/globalUnhandledRejection.js';
export { resetInstrumentationHandlers } from './instrument/_handlers.js';
export { filenameIsInApp } from './node-stack-trace.js';
export { escapeStringForRegex } from './vendor/escapeStringForRegex.js';
export { supportsHistory } from './vendor/supportsHistory.js';
