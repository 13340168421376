import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'account-closed',
  templateUrl: './account-closed.component.html',
})
export class AccountClosedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
