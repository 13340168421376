import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PlanQuotaModel, QuotaType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeComponent, IconComponent } from '@sb/ui';
import { Dictionary } from 'lodash';

@Component({
  selector: 'app-quota-card-rule',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslateModule, BadgeComponent],
  templateUrl: './quota-card-rule.component.html',
})
export class QuotaCardRuleComponent {
  @Input()
  public subscriptionPlanQuotas: Dictionary<PlanQuotaModel>;

  @Input()
  public quotaType: QuotaType.DEPARTMENTS | QuotaType.TEAMS;
  @Input()
  public inputQuotaType = QuotaType;
}
