import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '../reducers/auth/auth.service';

@Component({
  template: ``,
})
export class LogoutComponent implements OnInit {
  public constructor(
    private readonly authService: AuthService,
    private readonly cookieService: CookieService,
  ) {}

  public ngOnInit() {
    this.authService.logout().then(() => {
      this.cookieService.delete('hubspotChatVisible');
      // Reload the page to clear the cache after short delay to ensure the logout action is sent
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
  }
}
