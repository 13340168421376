import { Routes } from '@angular/router';

import { EmployeesLoadedGuard } from '../../../reducers/orm/employee/employees-loaded.guard';
import { SubscriptionPlanGuard } from '../../../shared/subscription-plan/subscription-plan.guard';
import { PlanType } from '../../+reports/shared/subscriptions/subscription.model';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { MyAbsenceComponent } from '../../dashboard/my-absence/my-absence.component';
import { MyDashboardComponent } from '../../dashboard/my-dashboard/my-dashboard.component';
import { MyHoursComponent } from '../../dashboard/my-hours/my-hours.component';
import { MyPlusMinusComponent } from '../../dashboard/my-plus-minus/my-plus-minus.component';
import { MyScheduleDateResolver } from '../../dashboard/my-schedule/my-schedule-date.resolver';
import { MyScheduleComponent } from '../../dashboard/my-schedule/my-schedule.component';
import { PermissionGuard } from '../../permission-guard.service';

export const DashboardMenuRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [EmployeesLoadedGuard],
    children: [
      {
        path: 'my-overview',
        component: MyDashboardComponent,
        data: {
          animation: 'myDashboard',
        },
      },
      {
        path: 'my-schedule',
        component: MyScheduleComponent,
        data: {
          animation: 'mySchedule',
          type: 'my-schedule',
        },
        resolve: {
          date: MyScheduleDateResolver,
        },
      },
      {
        path: 'my-hours',
        component: MyHoursComponent,
        data: {
          animation: 'myHours',
          permission: ['View all timesheets', 'View own timesheet'],
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'my-absence',
        component: MyAbsenceComponent,
        data: {
          animation: 'myAbsence',
          permission: ['View absentee', 'View own absentee', 'View vacation hours'],
          subscriptionPlan: PlanType.BASIC,
        },
        canActivate: [PermissionGuard, SubscriptionPlanGuard],
      },
      {
        path: 'my-plus-minus',
        component: MyPlusMinusComponent,
        data: {
          animation: 'myPlusMinus',
          permission: ['View plus min hours', 'View own plus min hours'],
        },
        canActivate: [PermissionGuard],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard/my-overview',
      },
    ],
  },
];
