<div class="dash-block">
  <div class="dash-block__header">
    <div class="dash-block__title" translate>My open shifts</div>
    <div class="dash-block__select-header form-group flex items-center">
      <label for="show-period" class="col-form-label p-0 m-0" translate>Period</label>&nbsp;
      <select class="select-small" [formControl]="period" id="show-period">
        <option value="1" translate>1 week</option>
        <option value="2" translate>2 weeks</option>
        <option value="3" translate>3 weeks</option>
        <option value="4" translate>4 weeks</option>
      </select>
    </div>
  </div>
  <div class="dash-block__body">
    <content-state
      [isLoading]="isLoading"
      [isEmpty]="openShifts.length === 0"
      icon="calendar"
      size="extra-small"
      content="{{ 'No open shifts were found for this option' | translate }}"
    >
      <table class="table-widget table-uniform-rows">
        <thead>
          <tr>
            <th class="row-20" translate>Date</th>
            <th class="row-20" translate>Time</th>
            <th class="row-20" translate>Location</th>
            <th class="row-20" translate>Team</th>
            <th class="row-20" translate>Department</th>
            <th class="row-10"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let openShift of openShifts">
            <td>{{ openShift.date | date: 'PP' }}</td>
            <td>
              <span>{{ openShift.starttime | time }}</span>
              <span *ngIf="!openShift.hide_end_time"> - {{ openShift.endtime | time }}</span>
            </td>
            <td>{{ openShift.location_name }} - {{ openShift.department_name }}</td>
            <td>{{ openShift.Team?.name }}</td>
            <td>{{ openShift.department_name }}</td>
            <td>
              <a [routerLink]="['', { outlets: { modal: ['schedule-open-shift', openShift.occurrence_id] } }]">
                <icon class="si" svgIcon="zoom"></icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
