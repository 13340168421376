import { NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '../../../icon.component';

@Component({
  selector: 'sb-profile-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, NgStyle, NgIf],
  template: `<a class="flex w-full px-2" [attr.href]="href" [attr.target]="!!href ? '_blank' : undefined">
    <div
      class="flex flex-1 items-center justify-between gap-2 rounded-[5px] p-3.5 text-lg font-semibold text-grey-800 hover:bg-grey-100
   xl:rounded xl:px-2 xl:py-1 xl:text-base xl:font-normal"
    >
      <span>
        <icon *ngIf="!!icon" [svgIcon]="icon"></icon>
        {{ title }}
      </span>
      <div
        *ngIf="notificationCount > 0"
        class="flex h-[17px] w-[17px] items-center justify-center rounded-full bg-error-500 font-bold text-white"
        [ngStyle]="{ 'font-size': 30 * 0.35 + 'px' }"
      >
        {{ notificationCount }}
      </div>
    </div>
  </a>`,
})
export class SBProfileItemComponent {
  @Input()
  public title: string;
  @Input()
  public icon: string;
  @Input()
  public notificationCount: number;
  @Input()
  public href: string;
}
