<div class="m-0 h-full bg-grey-25">
  <div class="account-closed text-word-mark-700">
    <div class="account-closed__title text-word-mark-700">{{ 'Account closed' | translate }}</div>
    <h3 class="font-bold">{{ 'Thank you for using the software' | translate }}</h3>

    <div class="text-base">
      {{ 'All data will be removed after 30 days!' | translate }}<br />
      <a class="font-semibold" href="/">{{ 'Back to login' | translate }}</a>
    </div>
  </div>
</div>
