import {
  getEmployeeTeamDepartmentsWithoutHiddenTeams,
  getPermissionState,
  hasPermission,
  PermissionCheck,
} from '../../../../reducers/auth/permission.helper';
import { createSelector } from 'reselect';
import { getActiveEmployees, getEmployees } from '../../../../reducers/orm/employee/employee.service';
import orderBy from 'lodash-es/orderBy';
import { toDate, subDays } from 'date-fns';
import { getContractEntities } from '../../../../reducers/orm/contract/contract.service';
import find from 'lodash-es/find';
import { format, parseDate } from '../../../../shared/date.helper';

export const getEmployeeBirthdaysInPeriod = (minDate, maxDate) =>
  createSelector(
    getPermissionState,
    getEmployeeTeamDepartmentsWithoutHiddenTeams,
    getActiveEmployees,
    (permissionState, employeeTeamDepartments, activeEmployees) => {
      const filteredEmployees = activeEmployees.filter((employee) => {
        if (employee.birthday === null || employee.birthday < minDate || employee.birthday > maxDate) {
          return false;
        }

        const check: PermissionCheck = {
          userId: employee.id,
          departments: employeeTeamDepartments[employee.id],
          permissions: ['View all users'],
        };

        return hasPermission(check, permissionState);
      });

      return orderBy(filteredEmployees, ['birthday'], ['asc']);
    }
  );

export const getEmployeesWithContracts = createSelector(getEmployees, getContractEntities, (employees, contracts) => employees.map((employee) => {
    const contract = find(contracts, (contract) => contract.user_id === employee.id);

    return {
      ...employee,
      contract,
    };
  }));

export const getEndingContractsForAuthenticatedUser = (minDate, maxDate) =>
  createSelector(
    getPermissionState,
    getEmployeeTeamDepartmentsWithoutHiddenTeams,
    getEmployeesWithContracts,
    (permissionState, employeeTeamDepartments, employees) => {
      const filteredEmployees = employees
        .filter((employee) => {
          if (employee.enddate === null || employee.enddate <= minDate || employee.enddate > maxDate) {
            return false;
          }

          const check: PermissionCheck = {
            userId: employee.id,
            departments: employeeTeamDepartments[employee.id],
            permissions: ['View contracts'],
          };

          return hasPermission(check, permissionState);
        })
        .map((employee) => ({
            ...employee,
            enddate: format(subDays(parseDate(employee.enddate), 1), 'yyyy-MM-dd'),
          }));

      return orderBy(filteredEmployees, ['enddate', 'name'], ['asc', 'asc']);
    }
  );
