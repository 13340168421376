import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { sbIcons } from '@sb/svg-icons';
import { IconComponent } from '@sb/ui';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-header-icon, [sb-header-icon]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, NgClass],
  template: `
    <span
      class="flex items-center justify-center rounded-md p-2 text-grey-500 group-hover:bg-grey-100"
      [ngClass]="{
        'bg-primary-50': isActive,
        'hover:bg-primary-50': isActive
      }"
    >
      <sb-icon
        [size]="'lg'"
        [name]="icon"
        [ngClass]="{
          'text-primary-500': isActive,
          'group-hover:text-primary-500': isActive
        }"
      />
    </span>
    <span class="sr-only"><ng-content></ng-content></span>
  `,
})
export class SBHeaderIconComponent {
  @Input({ required: true })
  public icon: sbIcons;
  @Input()
  public isActive = false;

  @HostBinding('class')
  public classes = clsx(
    'group flex h-full w-10 cursor-pointer appearance-none items-center justify-center border-none bg-transparent focus:outline-none',
  );
}
