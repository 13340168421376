import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DashboardFilterType } from '@app/reducers/page-filters/page-filters-dashboard.config';
import { selectAllowedDashboardFilter } from '@app/reducers/page-filters/page-filters-dashboard.selectors';
import { select, Store } from '@ngrx/store';
import { addMonths } from 'date-fns';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { fieldChangeWithStart } from '../../../../../forms/form.helper';
import { AppState } from '../../../../../reducers/index';
import { ContractService } from '../../../../../reducers/orm/contract/contract.service';
import { PageFiltersService } from '../../../../../reducers/page-filters/page-filters.service';
import { format } from '../../../../../shared/date.helper';
import { getEndingContractsForAuthenticatedUser } from '../widget.helper';

@Component({
  selector: 'ending-contracts-widget',
  templateUrl: './ending-contracts-widget.component.html',
})
export class EndingContractsWidgetComponent implements OnInit, OnDestroy {
  public period: UntypedFormControl = new UntypedFormControl('1');
  public isLoading = false;
  public employeesWithContracts = [];
  private dataSubs = new Subscription();

  private readonly filterType = DashboardFilterType.ENDING_CONTRACTS;

  public constructor(
    private store: Store<AppState>,
    private contractService: ContractService,
    private pageFilterService: PageFiltersService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.store.pipe(select(selectAllowedDashboardFilter(this.filterType))).subscribe((dashboardFilter) => {
        const filterVal = dashboardFilter.widgetPeriod;
        if (filterVal) {
          this.period.setValue(filterVal);
        }
      }),
    );

    this.dataSubs.add(
      this.period.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.pageFilterService.setFilter('dashboard', this.filterType, {
          widgetPeriod: this.period.value,
        });
      }),
    );

    this.dataSubs.add(
      fieldChangeWithStart(this.period)
        .pipe(
          tap(() => {
            this.isLoading = true;
          }),
          switchMap((period) =>
            this.contractService
              .load({
                minDate: format(new Date(), 'yyyy-MM-dd'),
                maxDate: format(addMonths(new Date(), period), 'yyyy-MM-dd'),
              })
              .pipe(switchMap(() => this.getEndingContractsForPeriod(period))),
          ),
        )
        .subscribe((employees) => {
          this.isLoading = false;
          this.employeesWithContracts = employees;
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  private getEndingContractsForPeriod(period) {
    const minDate = format(new Date(), 'yyyy-MM-dd');
    const maxDate = format(addMonths(new Date(), period), 'yyyy-MM-dd');

    return this.store.select(getEndingContractsForAuthenticatedUser(minDate, maxDate));
  }
}
