import { TranslateCompiler } from '@ngx-translate/core';
let phraseAppEditor = false;
function initializePhraseAppEditor(config) {
  if (phraseAppEditor) return;
  phraseAppEditor = true;
  const defaultConfig = {
    phraseEnabled: false,
    prefix: '{{__',
    suffix: '__}}',
    useOldICE: false,
    fullReparse: true
  };
  window.PHRASEAPP_ENABLED = config.phraseEnabled || false;
  window.PHRASEAPP_CONFIG = {
    ...defaultConfig,
    ...config,
    origin: 'ngx-translate-phraseapp'
  };
  if (config.phraseEnabled) {
    const phraseapp = document.createElement('script');
    phraseapp.async = true;
    if (!config.useOldICE) {
      phraseapp.type = 'module';
      phraseapp.src = `https://d2bgdldl6xit7z.cloudfront.net/latest/ice/index.js`;
    } else {
      phraseapp.type = 'text/javascript';
      phraseapp.src = `https://phrase.com/assets/in-context-editor/2.0/app.js?${new Date().getTime()}`;
    }
    var scriptEl = document.getElementsByTagName('script')[0];
    if (scriptEl?.parentNode) {
      scriptEl.parentNode.insertBefore(phraseapp, scriptEl);
    } else {
      document.body.appendChild(phraseapp);
    }
  }
}
function isPhraseEnabled() {
  return !!window.PHRASEAPP_ENABLED;
}
function escapeId(id) {
  let config = window.PHRASEAPP_CONFIG;
  return config.prefix + 'phrase_' + id + config.suffix;
}
class PhraseAppCompiler extends TranslateCompiler {
  constructor() {
    super();
  }
  compile(value, lang) {
    if (!isPhraseEnabled()) {
      return value;
    }
    return escapeId(value);
  }
  compileTranslations(translations, lang) {
    if (!isPhraseEnabled()) {
      return translations;
    }
    translations = this.flatten(translations);
    const escapedTranslations = {};
    Object.keys(translations).forEach((key, _) => {
      escapedTranslations[key] = escapeId(key);
    });
    return escapedTranslations;
  }
  flatten(object) {
    const separator = '.';
    const isValidObject = (value = {}) => {
      if (!value) {
        return false;
      }
      const isArray = Array.isArray(value);
      const isΟbject = Object.prototype.toString.call(value) === '[object Object]';
      const hasKeys = !!Object.keys(value).length;
      return !isArray && isΟbject && hasKeys;
    };
    const walker = (child = {}, path = []) => {
      return Object.assign({}, ...Object.keys(child).map(key => isValidObject(child[key]) ? walker(child[key], path.concat([key])) : {
        [path.concat([key]).join(separator)]: child[key]
      }));
    };
    return Object.assign({}, walker(object));
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { PhraseAppCompiler, initializePhraseAppEditor };
