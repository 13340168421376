import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SummaryItemComponent } from '@sb/ui';

import { TimePipe } from '../../../pipes/time.pipe';
import { EmployeeModel } from '../../../reducers/orm/employee/employee.model';
import { ExchangeModel } from '../../../reducers/orm/exchange/exchange.model';
import { AvatarBadgeGroupComponent } from '../../../shared/sb-lib/avatar-badge-group/avatar-badge-group.component';
import { DatePipe } from '../../../shared/sb-lib/calendar/pipes/date.pipe';
import { SidebarHeaderComponent } from './sidebar-header.component';
import { SidebarRequestItemDirective } from './sidebar-request-item.directive';

type EnhancedExchange = ExchangeModel & { relevantEmployees: EmployeeModel[] };

export enum ExchangeRequestContext {
  PENDING_APPROVAL = 'pending-approval',
  INCOMING = 'incoming',
  DEFAULT = 'default',
}

@Component({
  selector: 'exchange-requests',
  templateUrl: './exchange-requests.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    DatePipe,
    TimePipe,
    AvatarBadgeGroupComponent,
    SummaryItemComponent,
    NgClass,
    SidebarHeaderComponent,
    SidebarRequestItemDirective,
  ],
})
export class ExchangeRequestsComponent implements OnChanges {
  public enhancedExchanges: EnhancedExchange[];

  @Input()
  public exchanges: ExchangeModel[];

  @Input()
  public showAvatars: boolean;

  @Input()
  public showIndicator;

  @Input({ required: true })
  public title: string;

  @Input()
  public context: ExchangeRequestContext = ExchangeRequestContext.DEFAULT;

  public constructor(private router: Router) {}

  public ngOnChanges() {
    this.enhancedExchanges = this.exchanges.map((exchange) => {
      const relevantEmployees = this.getExchangeEmployees(exchange);
      return { ...exchange, relevantEmployees };
    });
  }

  private getExchangeEmployees(exchange: ExchangeModel): EmployeeModel[] {
    const employees = [];
    if (exchange.User) {
      employees.push(exchange.User);
    }
    switch (this.context) {
      case ExchangeRequestContext.PENDING_APPROVAL:
        if (exchange.AcceptUser) {
          employees.push(exchange.AcceptUser);
        }
        break;
      case ExchangeRequestContext.INCOMING:
        break;
      default:
        if (exchange.ExchangesUser?.length > 0) {
          exchange.ExchangesUser.forEach((exchangeUser) => {
            employees.push(exchangeUser.User);
          });
        }
        break;
    }
    return employees;
  }

  public showRequest(exchange) {
    const departmentId = exchange?.Schedule?.Team.department_id;
    this.router.navigate(['', { outlets: { modal: ['exchange-request', exchange.id, { department: departmentId }] } }]);
  }
}
