<div class="m-0 h-full bg-grey-25">
  <div class="page-error text-word-mark-700">
    <div class="page-error__code text-word-mark-700">404</div>
    <h3 class="font-bold">{{ 'We couldn’t find the page..' | translate }}</h3>

    <div class="text-base">
      {{ 'Sorry, but the page you are looking for was either not found or does not exist' | translate }}.<br />
      {{ 'Try refreshing the page or click the button below to go back to the application' | translate }}.<br /><br />
      <a class="font-semibold" href="/">{{ 'Back to application' | translate }}</a>
    </div>
  </div>
</div>
