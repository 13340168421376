import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DashboardFilterType } from '@app/reducers/page-filters/page-filters-dashboard.config';
import { selectAllowedDashboardFilter } from '@app/reducers/page-filters/page-filters-dashboard.selectors';
import { select, Store } from '@ngrx/store';
import { subWeeks } from 'date-fns';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { fieldChangeWithStart } from '../../../../../forms/form.helper';
import { AppState } from '../../../../../reducers/index';
import {
  getNewsItemsForAuthenticatedUser,
  NewsItemService,
} from '../../../../../reducers/orm/news-item/news-item.service';
import { PageFiltersService } from '../../../../../reducers/page-filters/page-filters.service';
import { format } from '../../../../../shared/date.helper';

@Component({
  selector: 'my-news-items-widget',
  templateUrl: './my-news-items-widget.component.html',
})
export class MyNewsItemsWidgetComponent implements OnInit, OnDestroy {
  public period: UntypedFormControl = new UntypedFormControl('1');
  public isLoading = false;
  public newsItems = [];
  private dataSubs = new Subscription();
  private readonly filterType = DashboardFilterType.MY_NEWS_ITEMS;

  public constructor(
    private store: Store<AppState>,
    private newsItemService: NewsItemService,
    private pageFilterService: PageFiltersService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.store.pipe(select(selectAllowedDashboardFilter(this.filterType))).subscribe((dashboardFilter) => {
        const filterVal = dashboardFilter.widgetPeriod;
        if (filterVal) {
          this.period.setValue(filterVal);
        }
      }),
    );

    this.dataSubs.add(
      this.period.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.pageFilterService.setFilter('dashboard', this.filterType, {
          widgetPeriod: this.period.value,
        });
      }),
    );

    this.loadData();
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  private loadData() {
    this.isLoading = true;
    const params = { include: 'User,Attachment,Department', limit: 999 };
    this.dataSubs.add(
      this.newsItemService
        .load(params)
        .pipe(
          switchMap(() => fieldChangeWithStart(this.period)),
          switchMap((period) => this.getNewsItemsForPeriod(period)),
        )
        .subscribe((newsItems) => {
          this.isLoading = false;
          this.newsItems = newsItems;
        }),
    );
  }

  private getNewsItemsForPeriod(period) {
    const minDate = format(subWeeks(new Date(), period), 'yyyy-MM-dd');
    const maxDate = format(new Date(), 'yyyy-MM-dd');
    return this.store.select(getNewsItemsForAuthenticatedUser(minDate, maxDate));
  }
}
