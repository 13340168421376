<div class="h-full overflow-y-scroll">
  <div class="fixed z-10 flex h-12 w-full flex-1 items-center justify-center xl:justify-start xl:pl-4">
    <a class="h-full py-3">
      <img class="h-full" src="/assets/images/shiftbase_logo_dark.svg" />
    </a>
  </div>

  <div class="mt-10 flex justify-center">
    <div class="flex max-w-2xl grow flex-col justify-center gap-6 px-5 py-10">
      <div class="mb-6" *ngIf="steps">
        <sb-progress-bar
          [title]="
            'Step [currentStep] of [totalSteps]'
              | translate
              | translationParams: { currentStep: currentStepIndex + 1, totalSteps: steps.length }
          "
          [level]="progress"
        ></sb-progress-bar>
      </div>
      <ng-container *ngIf="subscription?.plan">
        <ng-container [ngSwitch]="currentStepIndex">
          <app-downgrade-intro
            *ngSwitchCase="downgradeSteps.INTRO"
            [planName]="subscription?.plan?.name"
            (incrementStep)="incrementStep($event)"
          ></app-downgrade-intro>
          <app-downgrade-locations
            *ngSwitchCase="downgradeSteps.LOCATIONS"
            [planName]="subscription?.plan?.name"
            [subscriptionPlanQuotas]="subscription?.plan?.quotas"
          ></app-downgrade-locations>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
