<div class="dash-block">
  <div class="dash-block__header">
    <div class="dash-block__title" translate>My timesheets</div>
    <div class="dash-block__select-header form-group flex items-center">
      <label for="show-period" class="col-form-label p-0 m-0" translate>Period</label>&nbsp;
      <select class="select-small" [formControl]="period" id="show-period">
        <option value="1" translate>1 week</option>
        <option value="2" translate>2 weeks</option>
        <option value="3" translate>3 weeks</option>
        <option value="4" translate>4 weeks</option>
      </select>
    </div>
  </div>
  <div class="dash-block__body">
    <content-state
      [isLoading]="isLoading"
      [isEmpty]="timesheets.length === 0"
      icon="stopwatch"
      size="extra-small"
      content="{{ 'No timesheets were found for this option' | translate }}"
    >
      <table class="table-widget table-uniform-rows">
        <thead>
          <tr>
            <th class="row-20" translate>Date</th>
            <th class="row-30" translate>Location</th>
            <th class="row-20" translate>Time</th>
            <th class="row-15" translate>Totals</th>
            <th class="row-15" translate>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let timeSheet of timesheets">
            <td>{{ timeSheet.date | date: 'PP' }}</td>
            <td>{{ timeSheet.location_name }} - {{ timeSheet.department_name }}</td>
            <td>{{ timeSheet.starttime | time }} - {{ timeSheet.endtime | time }}</td>
            <td>{{ timeSheet.total | decimalToTime }}</td>
            <td>
              <icon class="si-lg" [svgIcon]="timeSheet.status"></icon>
            </td>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
