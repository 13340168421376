import { of as observableOf, Subscription } from 'rxjs';

import { switchMap, take } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../reducers/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { getPermissionState, hasPermission } from '../../reducers/auth/permission.helper';
import { AccountService } from '../../reducers/account/account.service';
import { InvoiceModel, InvoiceSource } from '@app/+authenticated/+account/billing/billing.model';
import { AccountApi } from '@app/reducers/account/account.api';

@Component({
  selector: 'locked',
  templateUrl: './locked.component.html',
})
export class LockedComponent implements OnInit, OnDestroy {
  public unpaidInvoices;
  public mode: 'manager' | 'employee';

  private dataSubs = new Subscription();

  public isInvoicePaymentPageLoading: { [key: string]: boolean } = {};

  public constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private accountService: AccountService,
    private cd: ChangeDetectorRef,
    private api: AccountApi
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.accountService
        .load()
        .pipe(
          switchMap(() => this.store.select(getPermissionState)),
          switchMap((permissionState) => {
            const manageAccount = hasPermission(
              {
                permissions: ['Manage account'],
                userId: 'me',
                departments: 'any',
              },
              permissionState
            );

            if (manageAccount) {
              return this.accountService.fetchExpiredInvoices();
            } else {
              this.mode = 'employee';
              return observableOf(false);
            }
          }),
          take(1)
        )
        .subscribe({
          next: (invoices: InvoiceModel[]) => {
            if (invoices) {
              this.mode = 'manager';
              this.unpaidInvoices = invoices.filter((invoice) => invoice.status !== 'paid');
              if (this.unpaidInvoices.length > 0) return;
            }
            this.router.navigate(['dashboard']);
          },
        })
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public backToLogin() {
    this.authService.logout().then(() => {
      this.router.navigate(['login']);
    });
  }

  public openInvoice(invoice: InvoiceModel): void {
    if (invoice.source === InvoiceSource.MONEYBIRD && invoice.payment_url) {
      window.open(invoice?.payment_url, '_blank');
    }

    if (invoice.source === InvoiceSource.CHARGEBEE && invoice.status !== 'paid') {
      this.isInvoicePaymentPageLoading[invoice.id] = true;
      this.cd.detectChanges();
      this.dataSubs.add(
        this.api.fetchChargebeePaymentPage().subscribe({
          next: (paymentPage) => {
            this.isInvoicePaymentPageLoading[invoice.id] = false;
            this.cd.detectChanges();
            window.open(paymentPage.hosted_page.url, '_blank');
          },
        })
      );
    }

    this.dataSubs.add(
      this.api.fetchInvoicePdf(invoice.id).subscribe((invoicePdf) => {
        window.open(invoicePdf.url, '_blank');
      })
    );
  }
}
