import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionModel } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { AppState } from '@app/reducers';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { DataStartup } from '@app/startup/data-startup.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarComponent } from 'libs/ui/src/lib/progress-bar/progress-bar.component';
import { Subscription } from 'rxjs';

import { DowngradeIntroComponent } from './components/steps/downgrade-intro.component';
import { DowngradeLocationsComponent } from './components/steps/downgrade-locations.component';

enum DowngradeSteps {
  INTRO,
  LOCATIONS,
}

@Component({
  selector: 'app-downgrade',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ProgressBarComponent,
    TranslateModule,
    TranslationParamsPipe,
    DowngradeIntroComponent,
    DowngradeLocationsComponent,
  ],
  templateUrl: './downgrade-steps.component.html',
})
export class DowngradeComponent implements OnInit, OnDestroy {
  public steps = [DowngradeSteps.INTRO, DowngradeSteps.LOCATIONS];

  public downgradeSteps = DowngradeSteps;

  public subscription: SubscriptionModel;

  public currentStepIndex = 0;
  public progress: number;

  public dataSubs = new Subscription();

  public constructor(
    private dataStartup: DataStartup,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(this.dataStartup.requiredLoader().subscribe());
    this.dataSubs.add(
      this.store.select(getAccountSubscription).subscribe((subscription) => {
        this.subscription = subscription;
        this.cd.detectChanges();
      }),
    );

    this.calculateProgress();
  }

  public incrementStep() {
    this.currentStepIndex += 1;
    this.calculateProgress();
  }

  public calculateProgress() {
    this.progress = ((this.currentStepIndex + 1) / this.steps.length) * 100;
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }
}
