import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'universal-link',
  templateUrl: './universal-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalLinkComponent implements OnInit, OnDestroy {
  private dataSubs = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.dataSubs.add(
      this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
        this.redirectTo(params.get('type'), params.get('id'));
      })
    );
  }

  ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  private async redirectTo(type: string, id: string) {
    switch (type) {
      case 'leave':
        await this.router.navigate(['dashboard/my-absence']);
        break;
      case 'employees':
        await this.router.navigate([type, id, 'overview']);
        break;
      case 'news':
        await this.router.navigate(['news', { newsId: id }]);
        break;
      case 'exchange-request':
      case 'schedule-open-shift':
        await this.router.navigateByUrl(`dashboard/my-overview(modal:${type}/${id})`);
        break;
      case 'absence-request':
        const absenceType = 'absentee-days-request';
        await this.router.navigateByUrl(`dashboard/my-overview(modal:${absenceType}/${id})`);
        break;
      case 'my-account':
        await this.router.navigateByUrl(`my-account/login`);
        break;

      default:
        await this.router.navigate(['dashboard/my-overview']);
        break;
    }
  }
}
