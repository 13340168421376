import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { defaultAbsencesDateRange } from '@app/reducers/orm/absence/absence.helper';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, IconComponent } from '@sb/ui';
import { addWeeks, format } from 'date-fns';
import { combineLatest, forkJoin, map, Observable, of as observableOf, switchMap, take } from 'rxjs';

import { AppState } from '../../../../reducers';
import { getAuthenticatedUserId } from '../../../../reducers/auth/auth.service';
import { AbsenceLoadRequest, EnhancedAbsenceModel } from '../../../../reducers/orm/absence/absence.model';
import {
  AbsenceService,
  getPendingAbsencesForAuthenticatedUser,
} from '../../../../reducers/orm/absence/absence.service';
import { ExchangeModel } from '../../../../reducers/orm/exchange/exchange.model';
import {
  ExchangeService,
  getIncomingExchangesForAuthenticatedUser,
  getOutcomingExchangesForAuthenticatedUser,
} from '../../../../reducers/orm/exchange/exchange.service';
import { OpenShiftModel } from '../../../../reducers/orm/open-shift/open-shift.model';
import { getOpenShiftsForAuthenticatedUserWithoutRejections } from '../../../../reducers/orm/open-shift/open-shift.selector';
import { OpenShiftService } from '../../../../reducers/orm/open-shift/open-shift.service';
import { PlanType, SubscriptionModel } from '../../../+reports/shared/subscriptions/subscription.model';
import { AbsenceRequestNotificationsComponent } from '../absence-request-notifications.component';
import { ExchangeRequestContext, ExchangeRequestsComponent } from '../exchange-requests.component';
import { SidebarComponent } from '../sidebar.component';
import { ContentStateComponent } from './../../../../shared/content-state/content-state.component';
import {
  hasAtleastSubscriptionPlan,
  SubscriptionPlanDirective,
} from './../../../../shared/subscription-plan/subscription-plan.directive';
import { OpenShiftsComponent } from './open-shifts.component';

@Component({
  selector: 'my-requests-sidebar',
  templateUrl: './my-requests-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    ExchangeRequestsComponent,
    OpenShiftsComponent,
    AbsenceRequestNotificationsComponent,
    ContentStateComponent,
    AsyncPipe,
    TranslateModule,
    SubscriptionPlanDirective,
    ButtonComponent,
  ],
})
export class MyRequestsSidebarComponent implements OnInit {
  private userId: string;
  private absenceRequest: AbsenceLoadRequest;

  public loading = false;
  public noRequests: Observable<boolean>;
  public incomingExchanges: Observable<ExchangeModel[]>;
  public outgoingExchanges: Observable<ExchangeModel[]>;
  public openShifts: Observable<OpenShiftModel[]>;
  public pendingAbsences: Observable<EnhancedAbsenceModel[]>;

  public planType = PlanType;
  public subscription: SubscriptionModel;
  public exchangeRequestContext = ExchangeRequestContext;

  public constructor(
    private sidebar: SidebarComponent,
    private openShiftService: OpenShiftService,
    private exchangeService: ExchangeService,
    private store: Store<AppState>,
    private absenceService: AbsenceService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    this.loadData();

    this.pendingAbsences = this.store.select(getPendingAbsencesForAuthenticatedUser(this.absenceRequest));
    this.openShifts = this.store.select(getOpenShiftsForAuthenticatedUserWithoutRejections);
    this.incomingExchanges = this.store.select(getIncomingExchangesForAuthenticatedUser);
    this.outgoingExchanges = this.store.select(getOutcomingExchangesForAuthenticatedUser);

    this.noRequests = combineLatest([
      this.pendingAbsences,
      this.openShifts,
      this.incomingExchanges,
      this.outgoingExchanges,
    ]).pipe(
      map(
        ([pendingAbsences, openShifts, incomingExchanges, outgoingExchanges]) =>
          pendingAbsences.length === 0 &&
          openShifts.length === 0 &&
          incomingExchanges.length === 0 &&
          outgoingExchanges.length === 0,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  public loadData() {
    const authId$ = this.store.select(getAuthenticatedUserId);
    const accountSubscription$ = this.store.select(getAccountSubscription);

    void forkJoin({
      authId: authId$.pipe(take(1)),
      accountSubscription: accountSubscription$.pipe(take(1)),
    })
      .pipe(
        switchMap(({ authId, accountSubscription }) => {
          this.userId = authId;
          this.subscription = accountSubscription;
          return forkJoin(
            this.loadAbsence(),
            this.loadOpenShifts(),
            this.loadIncomingExchanges(),
            this.loadOutgoingExchanges(),
          );
        }),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        complete: () => {
          this.loading = false;
        },
      });
  }

  public loadIncomingExchanges() {
    return this.exchangeService.loadIncoming(this.userId);
  }

  public loadOutgoingExchanges() {
    return this.exchangeService.loadOutgoing(this.userId);
  }

  public loadOpenShifts() {
    if (!hasAtleastSubscriptionPlan(PlanType.BASIC, this.subscription)) {
      return observableOf(false);
    }

    const minDate = format(new Date(), 'yyyy-MM-dd');
    const maxDate = format(addWeeks(new Date(), 26), 'yyyy-MM-dd');

    const requestData = {
      userId: this.userId,
      minDate: minDate,
      maxDate: maxDate,
    };

    return this.openShiftService.load(requestData);
  }

  public loadAbsence() {
    this.absenceRequest = {
      userId: this.userId,
      ...defaultAbsencesDateRange,
    };

    return this.absenceService.load(this.absenceRequest);
  }

  public close() {
    this.sidebar.close();
  }
}
