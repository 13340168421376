import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from '@app/+authenticated/+reports/shared/subscriptions/subscription.service';
import { SBHeaderAvatarComponent } from '@app/+authenticated/shared/sb-header/buttons/sb-header-avatar.component';
import { SBHeaderService } from '@app/+authenticated/shared/sb-header/sb-header.service';
import { SBProfileDropdownComponent } from '@app/+authenticated/shared/sb-header/sb-profile-dropdown/sb-profile-dropdown';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { AppState } from '@app/reducers';
import { EmployeeModel } from '@app/reducers/orm/employee/employee.model';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { doPermissionCheck } from '@app/reducers/orm/permission/permission.service';
import { LaddaDirective } from '@app/shared/ladda/ladda.directive';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, IconComponent } from '@sb/ui';
import { finalize, Observable, take } from 'rxjs';

@Component({
  selector: 'app-paused',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    TranslateModule,
    TranslationParamsPipe,
    LocaleDatePipe,
    IconComponent,
    ButtonComponent,
    LaddaDirective,
    SBHeaderAvatarComponent,
    SBProfileDropdownComponent,
  ],
  templateUrl: './paused.component.html',
})
export class PausedComponent {
  public canManageAccount: boolean;
  public loading: boolean;
  public authenticatedUser: EmployeeModel;
  public profileDropdownOpen$: Observable<boolean>;

  public constructor(
    private store: Store<AppState>,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private headerService: SBHeaderService,
  ) {
    void this.store
      .select(getAuthenticatedUser)
      .pipe(take(1))
      .subscribe((user) => {
        this.authenticatedUser = user;
      });

    void this.store
      .select(
        doPermissionCheck({
          permissions: 'Manage account',
          userId: 'me',
          departments: 'any',
        }),
      )
      .pipe(take(1))
      .subscribe((canManageAccount) => {
        this.canManageAccount = canManageAccount;
      });

    this.profileDropdownOpen$ = this.headerService.profileDropdownOpen$;
  }

  public resumeSubscription() {
    this.loading = true;
    void this.subscriptionService
      .resumeSubscription()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        this.router.navigate(['/dashboard']);
      });
  }
}
