@if (enhancedExchanges.length > 0) {
  <sidebar-header [title]="title" [indicator]="showIndicator ? enhancedExchanges.length : 0" />
  @for (exchange of enhancedExchanges; track $index) {
    <div sidebarRequestItem>
      <div (click)="showRequest(exchange)" class="cursor-pointer" [ngClass]="{ 'flex items-start gap-3': showAvatars }">
        @if (showAvatars) {
          <sb-avatar-badge-group
            class="shrink-0"
            [employees]="exchange.relevantEmployees"
            [truncateEmployeesAfter]="exchange.relevantEmployees.length > 2 ? 1 : 0"
          />
        }
        <sb-summary-item [compact]="true">
          <span firstRowLeft class="truncate">
            {{ exchange.Schedule?.location_name }}
            - {{ exchange.Schedule?.department_name }}
          </span>
          <span secondRowLeft>
            {{ exchange.date | date: 'PP' }}: {{ exchange.Schedule?.starttime | time }}
            @if (!exchange.Schedule?.hide_end_time) {
              - {{ exchange.Schedule?.endtime | time }}
            }
          </span>
        </sb-summary-item>
      </div>
    </div>
  }
}
