import { Component, HostBinding } from '@angular/core';
import { EmployeeOverviewData } from '@app/+authenticated/+employees/shared/employee-absence-overview/employee-absence-overview.component';
import { Store } from '@ngrx/store';
import { clsx } from 'clsx';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { getEmployeeTeamDepartments } from '../../../reducers/auth/permission.helper';
import { AppState } from '../../../reducers/index';
import { getAuthenticatedUser } from '../../../reducers/orm/employee/employee.service';
import { format, parseDate } from '../../../shared/date.helper';

@Component({
  selector: 'my-absence-component',
  template: `<employee-absence-overview [employee]="employee$ | async" />`,
})
export class MyAbsenceComponent {
  public employee$: Observable<EmployeeOverviewData>;

  @HostBinding('class')
  public readonly classes = clsx('block h-page overflow-auto');

  public constructor(private store: Store<AppState>) {
    this.employee$ = this.store.select(getAuthenticatedUser).pipe(
      filter((user) => !!user),
      withLatestFrom(this.store.select(getEmployeeTeamDepartments)),
      map(([employee, employeeDepartments]) => ({
        id: employee.id,
        startYear: format(parseDate(employee.startdate), 'yyyy'),
        endDate: employee.enddate || '',
        departments: employeeDepartments[employee.id],
      })),
    );
  }
}
