import { SubscriptionModel } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { createSelector } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import { getAccountCocInSchedule, getAccountSubscription } from '../account/account.service';
import { PermissionState } from '../auth/auth.model';
import {
  getPermissionState,
  hasAllPermissions,
  hasAllPermissionsForReports,
  hasPermission,
} from '../auth/permission.helper';
import {
  DashboardFilterType,
  pagedashboardFilterViewConfig,
  PermissionCheckType,
} from './page-filters-dashboard.config';
import { DashboardFilterState } from './page-filters.model';
import { getPageFiltersState } from './page-filters.service';

// for internal use only since we only want to display the allowed filters
const selectDashboardFilters = createSelector(getPageFiltersState, (pageFilters) => pageFilters.dashboard);

export const selectAllowedDashboardFilters = createSelector(
  selectDashboardFilters,
  getPermissionState,
  getAccountSubscription,
  getAccountCocInSchedule,
  (dashboardFilters, permissionState, accountSubscription, cocInSchedule): DashboardFilterState => {
    const allowedFilters: DashboardFilterState = Object.entries(dashboardFilters).reduce(
      (acc, [filterType, filterOptions]) => {
        // return only the filters that are allowed to be seen
        const allowed = allowedToSeeFilter(filterType, permissionState, accountSubscription, cocInSchedule);
        if (!allowed) {
          return acc;
        }
        acc[filterType] = { ...filterOptions };
        return acc;
      },
      {} as DashboardFilterState,
    );
    return allowedFilters;
  },
);

export const selectAllowedDashboardFilter = (filterType: DashboardFilterType) =>
  createSelector(selectAllowedDashboardFilters, (dashboardFilters) => dashboardFilters[filterType]);

// helper function to check if a filter can be seen based on the permission state and subscription plan
const allowedToSeeFilter = (
  filterType: string,
  permissionState: PermissionState,
  accountSubscription: SubscriptionModel,
  cocInSchedule: boolean,
): boolean => {
  // work around for the salary cost percentage filter, only show if the coc is in the schedule
  if (filterType === DashboardFilterType.SALARY_COST_PERCENTAGE_COC && !cocInSchedule) {
    return false;
  }

  const filterViewConfig = pagedashboardFilterViewConfig[filterType];

  // only filters with a view config can be shown
  // this is to prevent old typo filters to be shown (Ending Contracts instead of Ending contracts)
  // and to ensure that developers add view config with plan and permissions for new filters
  if (!filterViewConfig) {
    if (!environment.production) {
      console.warn('Filter type from state not found in view config', filterType);
    }
    return false;
  }

  const allowedBySubscriptionPlan =
    !filterViewConfig.subscriptionPlan ||
    hasAtleastSubscriptionPlan(filterViewConfig.subscriptionPlan, accountSubscription);

  const allowedByPermission =
    !filterViewConfig.hasPermission ||
    permissionCheck(filterViewConfig.permissionCheckType)(filterViewConfig.hasPermission, permissionState);

  return allowedBySubscriptionPlan && allowedByPermission;
};

const permissionCheck = (checkType: PermissionCheckType) => {
  switch (checkType) {
    case 'reports':
      return hasAllPermissionsForReports;
    case 'any':
      return hasPermission;
    default:
      return hasAllPermissions;
  }
};
