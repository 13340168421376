import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectOption } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { IntlFormatType } from '../../../../enums';
import { fieldChangeWithStart, formChange } from '../../../../forms/form.helper';
import { ValidationService } from '../../../../forms/validation.service';
import {
  recurringDayOfWeek,
  recurringInterval,
  recurringPeriod,
  ReportProjectionsRequestModel,
  ReportProjectionsResponseModel,
} from '../../../../reducers/orm/report-recurring/report-recurring.model';
import { ReportRecurringService } from '../../../../reducers/orm/report-recurring/report-recurring.service';
import { ReportService } from '../../../../reducers/orm/reports/report.service';
import { appendSecondsToTimeString } from '../../../../shared/date.helper';
import { RouteModalComponent } from '../../../route-modal.component';

@Component({
  selector: 'modal-report-recurring',
  templateUrl: './report-recurring-modal.component.html',
  styleUrls: ['report-recurring-modal.component.scss'],
})
export class ReportRecurringModalComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    periodSettings: new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required]),
    }),
    recurrenceSettings: new UntypedFormGroup({
      interval: new UntypedFormControl('', [Validators.required]),
      runTime: new UntypedFormControl('', [Validators.required, ValidationService.timeValidator]),
      dayOfWeek: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
    }),
    reportSettings: new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required]),
      parameters: new UntypedFormControl({}, [Validators.required]),
    }),
  });
  public recurringPeriod: SelectOption[] = recurringPeriod.map((period) => ({
    ...period,
    label: this.translate.instant(period.label),
  }));
  public recurringInterval: SelectOption[] = recurringInterval.map((interval) => ({
    ...interval,
    label: this.translate.instant(interval.label),
  }));
  public recurringDayOfWeek: SelectOption[] = recurringDayOfWeek.map((dayOfWeek) => ({
    ...dayOfWeek,
    label: this.translate.instant(dayOfWeek.label),
  }));
  public projections: ReportProjectionsResponseModel[] = [];
  public intlFormatType = IntlFormatType;
  @ViewChild(RouteModalComponent, { static: true })
  private modal: RouteModalComponent;
  private dataSubs = new Subscription();

  public constructor(
    private reportService: ReportService,
    private translate: TranslateService,
    private reportRecurringService: ReportRecurringService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      fieldChangeWithStart(this.form.get(['recurrenceSettings', 'interval'])).subscribe({
        next: (val) => {
          if (val === 'weekly') {
            this.form.get(['recurrenceSettings', 'dayOfWeek']).enable();
            return;
          }

          this.form.get(['recurrenceSettings', 'dayOfWeek']).disable();
        },
      }),
    );

    this.listenForProjections();
    const data = this.reportService.getReportParameters();

    if (!data) {
      return;
    }

    this.form.get(['reportSettings', 'parameters']).setValue(data.parameters);
    this.form.get(['reportSettings', 'type']).setValue(data.type);
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public close() {
    this.reportService.reportParametersWithType.next(null);
    this.modal.close();
  }

  public save() {
    if (!this.form.valid || this.isLoading) {
      return;
    }

    this.isLoading = true;

    const formVal = this.form.value;

    const postData = {
      ...formVal,
      recurrenceSettings: {
        ...formVal.recurrenceSettings,
        runTime: appendSecondsToTimeString(formVal.recurrenceSettings.runTime),
      },
    };

    this.dataSubs.add(
      this.reportRecurringService.createReport(postData).subscribe({
        next: () => {
          this.isLoading = false;
          this.close();
        },
        error: () => {
          this.isLoading = false;
        },
      }),
    );
  }

  private listenForProjections() {
    this.dataSubs.add(
      formChange(this.form)
        .pipe(
          filter(() => this.isFormValidForProjections()),
          debounceTime(500),
          switchMap(() => {
            const recurrenceSettings = this.form.get('recurrenceSettings').value;
            const data: ReportProjectionsRequestModel = {
              periodSettings: this.form.get('periodSettings').value,
              recurrenceSettings: {
                ...recurrenceSettings,
                runTime: appendSecondsToTimeString(recurrenceSettings.runTime),
              },
            };

            return this.reportRecurringService.getProjections(data);
          }),
        )
        .subscribe({
          next: (data) => {
            this.projections = data;
          },
        }),
    );
  }

  private isFormValidForProjections() {
    return this.form.get(['periodSettings']).valid && this.form.get(['recurrenceSettings']).valid;
  }
}
