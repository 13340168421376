import { NgModule } from '@angular/core';
import { ChartModule } from '@app/+authenticated/chart/chart.module';

import { SharedModule } from '../../../shared/shared.module';
import { EndingContractsWidgetComponent } from './dashboard-widgets/ending-contracts-widget/ending-contracts-widget.component';
import { MyExchangeWidgetComponent } from './dashboard-widgets/my-exchange-widget/my-exchange-widget.component';
import { MyNewsItemsWidgetComponent } from './dashboard-widgets/my-news-items-widget/my-news-items-widget.component';
import { MyOpenShiftWidgetComponent } from './dashboard-widgets/my-open-shift-widget/my-open-shift-widget.component';
import { MyScheduleWidgetComponent } from './dashboard-widgets/my-schedule-widget/my-schedule-widget.component';
import { MyTimesheetWidgetComponent } from './dashboard-widgets/my-timesheet-widget/my-timesheet-widget.component';
import { MyWeatherForecastWidgetComponent } from './dashboard-widgets/my-weather-forecast-widget/my-weather-forecast-widget.component';
import { UpcomingBirthdaysWidgetComponent } from './dashboard-widgets/upcoming-birthdays-widget/upcoming-birthdays-widget.component';

const imports = [SharedModule, ChartModule];

const declarations = [
  MyScheduleWidgetComponent,
  MyTimesheetWidgetComponent,
  MyWeatherForecastWidgetComponent,
  MyOpenShiftWidgetComponent,
  MyExchangeWidgetComponent,
  MyNewsItemsWidgetComponent,
  EndingContractsWidgetComponent,
  UpcomingBirthdaysWidgetComponent,
];

@NgModule({
  imports: imports,
  declarations: declarations,
  exports: [...imports, ...declarations],
})
export class MyDashboardModule {}
