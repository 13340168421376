import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { CustomFieldsAppliesTo } from '../../reducers/orm/custom-fields/custom-fields.model';

@Injectable()
export class CustomFieldGuard {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const appliesTo: CustomFieldsAppliesTo =
      CustomFieldsAppliesTo[<keyof typeof CustomFieldsAppliesTo>route.params.appliesTo?.toLocaleUpperCase()];
    return Object.values(CustomFieldsAppliesTo).includes(appliesTo);
  }
}
