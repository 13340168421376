import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { getAuthenticatedUserId } from '../../../reducers/auth/auth.service';
import { AppState } from '../../../reducers/index';
import { lazySelect } from '../../../shared/lazy-select.observable';

@Component({
  selector: 'my-hours',
  template: ` <div class="page__content flex-column flex px-[22px]">
    <employee-hours-overview
      [employeeId]="employeeId$ | async"
      [title]="'My timesheets' | translate"
    ></employee-hours-overview>
  </div>`,
})
export class MyHoursComponent {
  employeeId$ = this.store.pipe(lazySelect(getAuthenticatedUserId));

  constructor(private store: Store<AppState>) {}
}
