import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LocationsTableComponent } from '@app/+authenticated/+account/settings/+locations/locations-table/locations-table.component';
import { PlanQuotaModel, QuotaType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@sb/ui';
import { Dictionary } from 'lodash';

import { QuotaCardRuleComponent } from '../quota-card-rule/quota-card-rule.component';

@Component({
  selector: 'app-downgrade-locations',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TranslationParamsPipe,
    LocationsTableComponent,
    QuotaCardRuleComponent,
    ButtonComponent,
    RouterModule,
  ],
  templateUrl: './downgrade-locations.component.html',
})
export class DowngradeLocationsComponent {
  @Input()
  public subscriptionPlanQuotas: Dictionary<PlanQuotaModel>;

  @Input()
  public planName: string;
  public quotaType = QuotaType;

  public constructor(private router: Router) {}

  public continue(): void {
    // @HACK: find a better solution to reload the billing data
    setTimeout(() => {
      location.reload();
    });
  }
}
