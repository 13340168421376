import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DashboardFilterType } from '@app/reducers/page-filters/page-filters-dashboard.config';
import { selectAllowedDashboardFilter } from '@app/reducers/page-filters/page-filters-dashboard.selectors';
import { select, Store } from '@ngrx/store';
import { subWeeks } from 'date-fns';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { fieldChangeWithStart } from '../../../../../forms/form.helper';
import { getAuthenticatedUserId } from '../../../../../reducers/auth/auth.service';
import { AppState } from '../../../../../reducers/index';
import {
  getTimesheetsForAuthenticatedUserWithinPeriod,
  TimesheetService,
} from '../../../../../reducers/orm/timesheet/timesheet.service';
import { PageFiltersService } from '../../../../../reducers/page-filters/page-filters.service';
import { format } from '../../../../../shared/date.helper';

@Component({
  selector: 'my-timesheet-widget',
  templateUrl: './my-timesheet-widget.component.html',
})
export class MyTimesheetWidgetComponent implements OnInit, OnDestroy {
  public period: UntypedFormControl = new UntypedFormControl('1');
  public isLoading = false;
  public timesheets = [];
  private dataSubs = new Subscription();

  private readonly filterType = DashboardFilterType.MY_TIMESHEETS;

  public constructor(
    private store: Store<AppState>,
    private timesheetService: TimesheetService,
    private pageFilterService: PageFiltersService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.store.pipe(select(selectAllowedDashboardFilter(this.filterType))).subscribe((dashboardFilter) => {
        const filterVal = dashboardFilter.widgetPeriod;
        if (filterVal) {
          this.period.setValue(filterVal);
        }
      }),
    );

    this.dataSubs.add(
      this.period.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.pageFilterService.setFilter('dashboard', this.filterType, {
          widgetPeriod: this.period.value,
        });
      }),
    );

    this.loadData();
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  private loadData() {
    this.isLoading = true;
    this.dataSubs.add(
      this.store
        .select(getAuthenticatedUserId)
        .pipe(
          switchMap((authenticatedUserId) => {
            const requestData = {
              userId: authenticatedUserId,
              minDate: format(subWeeks(new Date(), 4), 'yyyy-MM-dd'),
              maxDate: format(new Date(), 'yyyy-MM-dd'),
              rates: true,
            };
            return this.timesheetService.load(requestData);
          }),
          switchMap(() => fieldChangeWithStart(this.period)),
          switchMap((period) => this.getTimesheetForPeriod(period)),
        )
        .subscribe((timesheets) => {
          this.timesheets = timesheets;
          this.isLoading = false;
        }),
    );
  }

  private getTimesheetForPeriod(period) {
    const minDate = format(subWeeks(new Date(), period), 'yyyy-MM-dd');
    const maxDate = format(new Date(), 'yyyy-MM-dd');

    return this.store.select(getTimesheetsForAuthenticatedUserWithinPeriod(minDate, maxDate));
  }
}
