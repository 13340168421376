import { Injectable } from '@angular/core';
import { PlanType, SubscriptionModel } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { LockType } from '@app/shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.model';
import { SidebarMenuItem } from '@app/shared/sb-lib/sidebar/sidebar.model';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class DashboardHelperService {
  public getDashboardSidebarMenuItem(accountSubscription: SubscriptionModel): SidebarMenuItem[] {
    return [
      {
        label: _('Overview'),
        icon: 'my-overview',
        routerLink: '/dashboard/my-overview',
        routerLinkActiveOptions: { exact: true },
        state: {
          title: _('Overview'),
        },
      },
      {
        label: _('Schedule'),
        icon: 'calendar',
        routerLink: '/dashboard/my-schedule',
        routerLinkActiveOptions: { exact: true },
        state: {
          title: _('Schedule'),
        },
      },
      {
        label: _('Hours'),
        icon: 'stopwatch_v2',
        routerLink: '/dashboard/my-hours',
        routerLinkActiveOptions: { exact: true },
        state: {
          title: _('Hours'),
          permissions: ['View own timesheet', 'View all timesheets'],
        },
      },
      {
        label: _('Absence'),
        icon: 'calendar_denied',
        routerLink: '/dashboard/my-absence',
        routerLinkActiveOptions: { exact: true },
        state: {
          title: _('Absence'),
          permissions: ['View absentee', 'View own absentee', 'View vacation hours'],
          isLocked: !hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription),
          lockType: LockType.MY_ABSENCE,
        },
      },
      {
        label: _('Plus minus'),
        icon: 'plusmin',
        routerLink: '/dashboard/my-plus-minus',
        routerLinkActiveOptions: { exact: true },
        state: {
          title: _('Plus minus'),
          permissions: ['View plus min hours', 'View own plus min hours'],
          isLocked: !hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription),
          lockType: LockType.MY_PLUS_MINUS,
        },
      },
    ];
  }
}
