import { APP_INITIALIZER, EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { FeatureService } from '@app/startup/feature.service';

export function provideFeatureService(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const featureService = inject(FeatureService);
        return async () => {
          await featureService.load();
        };
      },
      multi: true,
    },
  ]);
}
