import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { selectAllowedDashboardFilters } from '@app/reducers/page-filters/page-filters-dashboard.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { DashboardFilterState } from '@reducers/page-filters/page-filters.model';
import { filterPersistenceDebounceTime, PageFiltersService } from '@reducers/page-filters/page-filters.service';
import { Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { fieldChangeWithStart } from '../../../forms/form.helper';
import { SidebarComponent } from '../../shared/sidebar/sidebar.component';

@Component({
  selector: 'dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardSidebarComponent implements OnInit, OnDestroy {
  private dataSubs = new Subscription();
  public checkboxesFormGroup = new UntypedFormGroup({});

  public controlsOrder = [];

  private dashboardFilters: DashboardFilterState;

  public constructor(
    private sidebar: SidebarComponent,
    private store: Store<AppState>,
    private filterService: PageFiltersService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.store.pipe(select(selectAllowedDashboardFilters), take(1)).subscribe((filters) => {
        this.dashboardFilters = filters;

        Object.entries(filters).forEach(([key, value]) => {
          this.controlsOrder.push(key);
          const control = new UntypedFormControl(value.visibility);
          this.checkboxesFormGroup.addControl(key, control);
        });
      }),
    );

    this.dataSubs.add(
      fieldChangeWithStart(this.checkboxesFormGroup)
        .pipe(debounceTime(filterPersistenceDebounceTime))
        .subscribe((formValue) => {
          Object.entries(formValue).forEach(([key, val]) => {
            this.dashboardFilters[key] = {
              ...this.dashboardFilters[key],
              visibility: val as boolean,
            };
          });
          this.filterService.setFilters('dashboard', { ...this.dashboardFilters });
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public toggle() {
    const controls = Object.values(this.checkboxesFormGroup.controls);
    const currentValue = controls[0].value;
    for (const control of controls) {
      control.setValue(!currentValue);
    }
  }

  public close() {
    this.sidebar.close();
  }
}
