<div class="authenticated">
  <sb-header />

  <div class="authenticated__wrapper">
    <div class="authenticated__content">
      <router-outlet></router-outlet>
    </div>
  </div>

  @if (checklist$ | async; as checklist) {
    <span>
      @if (onboardingChecklist.showChecklist) {
        <sb-checklist
          [ngClass]="!isChatActive ? 'right-8' : 'right-24'"
          [title]="'Your Shiftbase journey starts here' | translate"
          [subtitle]="'Quick steps towards streamlining workforce management' | translate"
          [progress]="{
            title: '[percentage]% complete' | translate | translationParams: { percentage: checklist.progress },
            subtitle: '[remaining] steps left' | translate | translationParams: { remaining: checklist.remainingSteps },
            level: checklist.progress
          }"
          [items]="checklist.items"
          (minimized)="showChecklistButton(onboardingChecklist.showChecklist)"
          (closed)="dismissChecklist()"
          (itemClicked)="navigateToOnboardingStep($event)"
        />
      }
      <sb-bubble-notification
        [ngClass]="!isChatActive ? 'right-8' : 'right-24'"
        [title]="'Get started' | translate"
        [notificationsCount]="checklist.remainingSteps"
        (clicked)="showChecklistButton(onboardingChecklist.showChecklist)"
      />
    </span>
  } @else if (!(authUser$ | async)?.verified) {
    <verification-banner />
  }

  <router-outlet name="aside"></router-outlet>
</div>
