import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Features } from '@app/enums';
import { AppState } from '@app/reducers';
import { getAccount } from '@app/reducers/account/account.service';
import { isAuthenticated } from '@app/reducers/auth/auth.service';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { FeatureService } from '@app/startup/feature.service';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { Event, EventHint } from '@sentry/angular-ivy';
import { combineLatest, skip, Subject, takeUntil, throttleTime } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SentryLoggingService implements OnDestroy {
  private destroyed$ = new Subject<void>();
  private errorListener$ = new Subject<Sentry.Exception>();
  public isInitialized = false;

  public constructor(
    private store: Store<AppState>,
    private featureService: FeatureService,
  ) {}

  public startLogging() {
    if (!this.sentryLoggingEnabled()) return;

    this.init();
  }

  private init() {
    if (this.isInitialized) return;

    if (!environment.sentryDsn) {
      throw new Error('Sentry DSN is not set');
    }

    if (!environment.env) {
      throw new Error('Environment is not set');
    }

    const { tracesSampleRate, profilesSampleRate } =
      this.featureService.getAllFeatureVariables(Features.SENTRY_LOGGING) || {};

    Sentry.init({
      dsn: environment.sentryDsn,
      environment: environment.env,
      release: environment.version,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.BrowserProfilingIntegration(),
      ],
      tracesSampleRate: typeof tracesSampleRate === 'number' ? tracesSampleRate : 0.0,
      profilesSampleRate: typeof profilesSampleRate === 'number' ? profilesSampleRate : 0.0,
      ignoreErrors: [/^Error: Could not load Refiner client.$/],
      beforeSend: (event: Event, hint: EventHint) => {
        if (!String.prototype.indexOf) {
          return null;
        }

        if (typeof hint?.originalException === 'undefined') {
          return null;
        }

        const error: any = hint.originalException;
        const errorMessage = typeof error === 'string' ? error : error?.message;
        const nonErrorExceptionEvent = event?.message?.includes('Non-Error exception captured');
        const nonErrorException = errorMessage?.includes('Non-Error exception captured');
        const httpFailureResponse = errorMessage?.includes('Http failure response');
        const oldChunks = errorMessage?.includes('Loading chunk');
        const oldImports = errorMessage?.includes('Failed to fetch dynamically imported module');

        if (nonErrorExceptionEvent || nonErrorException || httpFailureResponse || oldChunks || oldImports) {
          return null;
        }

        if (error instanceof HttpErrorResponse && error.status === 422) {
          return null;
        }

        return event;
      },
    });

    this.trackIdentity();

    void this.errorListener$.pipe(throttleTime(5000), takeUntil(this.destroyed$)).subscribe((exception) => {
      Sentry.captureException(exception);
    });

    this.isInitialized = true;
  }

  public handleError(exception): void {
    if (!this.sentryLoggingEnabled()) return;

    this.errorListener$.next(exception);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private trackIdentity() {
    void combineLatest([
      this.store.select(isAuthenticated).pipe(skip(1)),
      this.store.select(getAuthenticatedUser).pipe(skip(1)),
      this.store.select(getAccount).pipe(skip(1)),
    ])
      .pipe(takeUntil(this.destroyed$))
      /**
       * These 3 values come from different enpoints,
       * login is setting authenticated=true
       * but then employees brings the user data and
       * subscription the account data.
       * So it might happen that when the data is streamed into this subsription,
       * not everything is there (yet) and it looks like it's authenticated
       * without user or account info.
       * Something similar (but in the opposite direction)
       * happens during logout.
       */
      .subscribe(([authenticated, user, account]) => {
        Sentry.setExtra('authenticated', authenticated);

        if (!authenticated) {
          Sentry.setUser(null);
          return;
        }

        if (user) {
          Sentry.setUser({
            id: user.id,
            username: user.name,
            email: user.email,
          });
        }

        if (account) {
          Sentry.setExtra('Account', {
            id: account.id,
            name: account.company,
          });
        }
      });
  }

  private sentryLoggingEnabled(): boolean {
    return this.featureService.isFeatureActivated(Features.SENTRY_LOGGING);
  }
}
