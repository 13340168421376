import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { FeatureService } from '@app/startup/feature.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { initializePhraseAppEditor, PhraseConfig } from 'ngx-translate-phraseapp';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Features } from './enums';
import { MaskConfigService } from './providers/ngx-mask.provider';
import { AuthService } from './reducers/auth/auth.service';
import { SentryLoggingService } from './services/sentryLogging.service';
import { TrackingService } from './services/tracking.service';
import { HubSpotService } from './startup/hub-spot.service';
import { LanguageStartup } from './startup/language-startup.service';

@Component({
  selector: 'app-root',
  template: `
    <loading-bar></loading-bar>
    <router-outlet></router-outlet>
    <router-outlet name="modal"></router-outlet>
    <p-confirmPopup [defaultFocus]="'none'"></p-confirmPopup>
    <app-notifications></app-notifications>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  public errorSubscription: Subscription;
  public scrollSubscription: Subscription;

  private dataSubs = new Subscription();

  public constructor(
    private mdIconRegistry: MatIconRegistry,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private languageStartup: LanguageStartup,
    //Leave this because it starts tracking pageviews
    gtm: Angulartics2GoogleTagManager,
    private router: Router,
    private featureService: FeatureService,
    private hubSpotService: HubSpotService,
    private primengConfig: PrimeNGConfig,
    private trackingService: TrackingService,
    private sentryLoggingService: SentryLoggingService,
    private maskConfigService: MaskConfigService,
  ) {
    gtm.startTracking();
  }

  public ngOnInit(): void {
    this.trackingService.startTracking();
    this.sentryLoggingService.startLogging();
    this.maskConfigService.init();

    this.primengConfig.ripple = true;
    this.mdIconRegistry.addSvgIconSet(
      this.sanitizer.bypassSecurityTrustResourceUrl('svg/core-icon-set.svg?v=' + environment.version),
    );

    //do a handshake to get the authenticated state
    this.languageStartup.init();
    void this.auth.load().subscribe();

    this.scrollSubscription = this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        pairwise(),
        map(([prevEvent, currEvent]) => {
          const previousEvent = prevEvent && prevEvent.hasOwnProperty('url') ? (prevEvent as NavigationEnd) : null;
          const currentEvent = currEvent && currEvent.hasOwnProperty('url') ? (currEvent as NavigationEnd) : null;
          if (previousEvent && currentEvent) {
            // Check whether a modal is opened, then don't scroll
            const openModalCheck = !previousEvent.url.includes('modal') && currentEvent.url.includes('modal');
            // Check whether a modal is closed, then don't scroll
            const closeModalCheck = previousEvent.url.includes('modal') && !currentEvent.url.includes('modal');
            // Check whether a modal is opened after a current modal, then don't scroll
            const multipleModalCheck = previousEvent.url.includes('modal') && currentEvent.url.includes('modal');

            if (openModalCheck || closeModalCheck || multipleModalCheck) {
              return false;
            } else {
              return true;
            }
          }

          if (currentEvent) {
            if (currentEvent.url.includes('modal')) {
              return false;
            } else {
              return true;
            }
          }
        }),
        filter((mustScrollToTop) => !!mustScrollToTop),
      )
      .subscribe(() => window.scrollTo(0, 0));

    this.errorSubscription = this.router.events
      .pipe(filter((navigationEvent) => navigationEvent instanceof NavigationError))
      .subscribe((navigationEvent: NavigationError) => {
        const error = navigationEvent.error;
        if (
          error &&
          error.message &&
          (error.message.match(/Loading chunk [0-9]* failed/) ||
            navigationEvent.error.message.includes('Failed to fetch dynamically imported module'))
        ) {
          window.location.href = navigationEvent.url;
        }
      });

    this.initializeInContextEditor();
    this.hubSpotService.loadScript();
    this.hubSpotService.initializeChatWidget(true);
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
    this.scrollSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }

  private initializeInContextEditor() {
    // disable phraseapp in production regardless of other conditions
    if (!environment.phraseProjectId || !environment.phraseAccountId || environment.env === 'production') {
      return;
    }

    // disable phraseapp if feature is not activated
    if (!this.featureService.isEnabled(Features.PHRASE_IN_CONTEXT_EDITOR)) {
      return;
    }

    const config: PhraseConfig = {
      projectId: environment.phraseProjectId,
      accountId: environment.phraseAccountId,
      phraseEnabled: true,
      useOldICE: false,
      prefix: '{{__',
      suffix: '__}}',
      fullReparse: true,
      autoLowercase: false,
    };

    initializePhraseAppEditor(config);
  }
}
