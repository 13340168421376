<ng-container *ngIf="subscriptionPlanQuotas[quotaType]">
  <div class="flex justify-between">
    <span class="flex items-center font-semibold">
      <sb-icon
        [ngClass]="{
          'text-success-500': subscriptionPlanQuotas[quotaType]?.usage <= subscriptionPlanQuotas[quotaType]?.limit,
          'text-error-500': subscriptionPlanQuotas[quotaType]?.usage > subscriptionPlanQuotas[quotaType]?.limit
        }"
        class="mr-2"
        [size]="'lg'"
        [name]="
          subscriptionPlanQuotas[quotaType]?.usage > subscriptionPlanQuotas[quotaType]?.limit
            ? 'times-circle'
            : 'check-circle'
        "
      ></sb-icon>
      {{ quotaType === inputQuotaType.DEPARTMENTS ? ('Department limit' | translate) : ('Team limit' | translate) }}
    </span>
    <sb-badge
      class=""
      [color]="subscriptionPlanQuotas[quotaType]?.usage > subscriptionPlanQuotas[quotaType]?.limit ? 'red' : 'green'"
    >
      {{ subscriptionPlanQuotas[quotaType]?.usage }}/{{ subscriptionPlanQuotas[quotaType]?.limit }}
    </sb-badge>
  </div>
</ng-container>
