import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getPermissionState, hasPermission } from '@app/reducers/auth/permission.helper';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppState } from '../../reducers';

@Injectable()
export class DowngradeGuard {
  public constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean> | boolean {
    return this.store.select(getPermissionState).pipe(
      switchMap((permissionState) => {
        const hasDowngradePermission = hasPermission(
          {
            departments: 'any',
            permissions: ['Manage account', 'Edit user permissions', 'Edit contracts'],
          },
          permissionState,
        );

        if (hasDowngradePermission) {
          return observableOf(true);
        } else {
          this.redirectToDashboard();
          return observableOf(false);
        }
      }),
    );
  }

  private redirectToDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
