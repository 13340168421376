<sidebar class="sidebar">
  <dashboard-sidebar></dashboard-sidebar>
</sidebar>

<div class="page__content flex flex-col px-[22px]">
  <div class="flex items-center justify-between">
    @if (authenticatedUser$ | async; as currentEmployee) {
      <h2 class="m-0.5 text-2xl font-bold text-word-mark-800">
        {{ employeeGreetMessage | translate }} {{ currentEmployee.first_name }}! 👋
      </h2>
    }

    <div actionsNav class="content-header__actions mx-0">
      <button sb-button [color]="'grey'" (click)="toggleSideBar()" class="sb-button-responsive-label">
        <sb-icon [name]="'chevron-left'"></sb-icon>
        {{ 'Widgets' | translate }}
      </button>
    </div>
  </div>
  @if (showFeatureBanner) {
    <notification-banner
      (closeHandler)="closeFeatureBanner()"
      [notificationBannerMessage]="dashboardFeatureMessage"
    ></notification-banner>
  }

  <content-state
    [isEmpty]="hasNoWidget"
    [title]="'Widgets' | translate"
    [content]="'There are no active widgets' | translate"
  ></content-state>

  @if (dashboardFilters) {
    <div class="grid gap-x-8 gap-y-5 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
      @if (dashboardFilters[dashboardFilterType.MY_SCHEDULE]?.visibility) {
        <my-schedule-widget />
      }

      @if (dashboardFilters[dashboardFilterType.MY_TIMESHEETS]?.visibility) {
        <my-timesheet-widget />
      }

      @if (dashboardFilters[dashboardFilterType.MY_OPEN_SHIFTS]?.visibility) {
        <my-open-shift-widget />
      }

      @if (dashboardFilters[dashboardFilterType.MY_EXCHANGES]?.visibility) {
        <my-exchange-widget />
      }

      @if (dashboardFilters[dashboardFilterType.MY_NEWS_ITEMS]?.visibility) {
        <my-news-items-widget />
      }

      @if (dashboardFilters[dashboardFilterType.BIRTHDAYS]?.visibility) {
        <upcoming-birthdays-widget />
      }

      @if (dashboardFilters[dashboardFilterType.WEATHER_FORECASTS]?.visibility) {
        <my-weather-forecast-widget />
      }

      @if (dashboardFilters[dashboardFilterType.ENDING_CONTRACTS]?.visibility) {
        <ending-contracts-widget />
      }

      @if (dashboardFilters[dashboardFilterType.ABSENCE_PERCENTAGE]?.visibility) {
        <app-turnover-graph
          [graphType]="turnOverGrapDataTypes.SICK_LEAVE_PERCENTAGE"
          [mode]="turnOverGrapMode"
          [graphData]="graphData"
          [isLoading]="isLoading"
          [size]="turnOverGrapSize"
        />
      }

      @if (dashboardFilters[dashboardFilterType.SALARY_COST_PERCENTAGE]?.visibility) {
        <app-turnover-graph
          [graphType]="turnOverGrapDataTypes.SALARY_COST_PERCENTAGE"
          [mode]="turnOverGrapMode"
          [graphData]="graphData"
          [isLoading]="isLoading"
          [size]="turnOverGrapSize"
        />
      }

      @if (dashboardFilters[dashboardFilterType.SALARY_COST_PERCENTAGE_COC]?.visibility) {
        <app-turnover-graph
          [graphType]="turnOverGrapDataTypes.COC_PERCENTAGE"
          [mode]="turnOverGrapMode"
          [graphData]="graphData"
          [isLoading]="isLoading"
          [size]="turnOverGrapSize"
        />
      }

      @if (dashboardFilters[dashboardFilterType.SALARY_PER_HOUR]?.visibility) {
        <app-turnover-graph
          [graphType]="turnOverGrapDataTypes.SALARY_PER_HOUR"
          [mode]="turnOverGrapMode"
          [graphData]="graphData"
          [isLoading]="isLoading"
          [size]="turnOverGrapSize"
        />
      }

      @if (dashboardFilters[dashboardFilterType.TURNOVER_PER_HOUR]?.visibility) {
        <app-turnover-graph
          [graphType]="turnOverGrapDataTypes.TURNOVER_PER_HOUR"
          [mode]="turnOverGrapMode"
          [graphData]="graphData"
          [isLoading]="isLoading"
          [size]="turnOverGrapSize"
        />
      }

      @if (dashboardFilters[dashboardFilterType.WORKED_VS_SCHEDULED_HOURS]?.visibility) {
        <app-worked-hours-graph [mode]="turnOverGrapMode" [filterValues]="filters" [size]="turnOverGrapSize" />
      }
    </div>
  }
</div>
