import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { filter, map, switchMap, switchMapTo } from 'rxjs/operators';

import { AppState } from '../../reducers';
import { authState, isAuthenticated } from '../../reducers/auth/auth.service';

@Injectable()
export class LockedGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.retrieveAuthenticatedStatus().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          return observableOf(true);
        } else {
          this.redirectToLogin();
          return observableOf(false);
        }
      }),
    );
  }

  private retrieveAuthenticatedStatus() {
    return this.store.select(authState).pipe(
      map((state) => state.loading),
      filter((loading) => !loading),
      switchMapTo(this.store.select(isAuthenticated)),
    );
  }

  private redirectToLogin() {
    this.router.navigate(['login']);
  }
}
