import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { isValid } from 'date-fns';
import { Observable, of as observableOf } from 'rxjs';
import { combineLatest, first, map, switchMap } from 'rxjs/operators';

import { getAuthenticatedUserId } from '../../../reducers/auth/auth.service';
import { AppState } from '../../../reducers/index';
import { determineSchedulePeriod, getSelectedMyScheduleDate } from '../../../reducers/page-filters/page-filters.helper';
import { PageFiltersService } from '../../../reducers/page-filters/page-filters.service';
import { format, parseDate } from '../../../shared/date.helper';
import { lazySelect } from '../../../shared/lazy-select.observable';
import { ScheduleHelperService } from '../../+schedule/shared/schedule-helper.service';
import { calculateMyScheduleRange } from './personal-schedule.helper';

@Injectable()
export class MyScheduleDateResolver {
  public constructor(
    private store: Store<AppState>,
    private pageFiltersService: PageFiltersService,
    private scheduleHelper: ScheduleHelperService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.activeDate(route).pipe(
      map((date) => determineSchedulePeriod(date, 'month')),
      combineLatest(this.store.pipe(lazySelect(getAuthenticatedUserId))),
      switchMap(([period, userId]) => {
        const monthRange = calculateMyScheduleRange(period.selectedDate);

        const start = format(monthRange.start, 'yyyy-MM-dd');
        const end = format(monthRange.end, 'yyyy-MM-dd');

        const adaptedPeriod = {
          minDate: start,
          maxDate: end,
          selectedDate: period.selectedDate,
          periodType: 'month',
          viewType: 'employee',
        };

        this.pageFiltersService.setFilter('mySchedule', 'date', period.selectedDate);
        this.pageFiltersService.setFilter('schedule', 'period', adaptedPeriod);

        return this.scheduleHelper.userDataObs(start, end, userId);
      }),
      first(),
    );
  }

  protected activeDate(route: ActivatedRouteSnapshot): Observable<Date> {
    return observableOf(route.params).pipe(
      map((params: Params) => params['date']),
      combineLatest(this.store.pipe(lazySelect(getSelectedMyScheduleDate))),
      map(([routeDate, selectedDate]) => {
        if (!routeDate) {
          return selectedDate;
        }
        return routeDate;
      }),
      map((selectedDate) => {
        const parsed = parseDate(selectedDate);
        if (!isValid(parsed)) {
          return new Date();
        }

        return parsed;
      }),
    );
  }
}
