import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getISOWeekYear } from 'date-fns';
import groupBy from 'lodash-es/groupBy';
import { BehaviorSubject, Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { map, repeatWhen, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { getAuthenticatedUserId } from '../../../reducers/auth/auth.service';
import {
  getEmployeeTeamDepartments,
  getPermissionState,
  hasPermission,
} from '../../../reducers/auth/permission.helper';
import { AppState } from '../../../reducers/index';
import { getAbsenteeOptions } from '../../../reducers/orm/absentee-option/absentee-option.service';
import { LoadCorrectionRequest } from '../../../reducers/orm/correction/correction.api';
import { CorrectionService, getUserCorrections } from '../../../reducers/orm/correction/correction.service';
import { EmployeeModel } from '../../../reducers/orm/employee/employee.model';
import { getAuthenticatedUser } from '../../../reducers/orm/employee/employee.service';
import { format, parseDate, weekYearEnd, weekYearStart } from '../../../shared/date.helper';

function mapStats(weekStats, dayStats, corrections) {
  return {
    corrections: corrections,
    week_stats: weekStats ? weekStats.week_stats : weekStats,
    day_stats: groupDayStatsPerWeek(dayStats),
    totals: getTotals(weekStats, dayStats),
  };
}

function getTotals(weekStats, dayStats) {
  if (!weekStats && !dayStats) {
    return weekStats;
  }

  if (weekStats) {
    return weekStats.totals;
  }

  return dayStats.totals;
}

function groupDayStatsPerWeek(dayStats) {
  if (!dayStats) {
    return dayStats;
  }

  return groupBy(dayStats.day_stats, (dayStat: any) => {
    const date = parseDate(dayStat.date);
    return `${getISOWeekYear(date)}${format(date, 'II')}`;
  });
}

@Component({
  selector: 'my-plus-minus',
  template: ` <div class="page__content flex-column flex px-[22px]">
    <employee-plusmin-overview
      [title]="'My plus minus hours' | translate"
      [start]="(user | async)?.start"
      [userId]="(user | async)?.id"
      [year]="year$ | async"
      [stats]="stats | async"
      [loadingCorrections]="loadingCorrections"
      [loadingStats]="loadingStats"
      [absenteeOptions]="absenteeOptions | async"
      [showAbsence]="showAbsence$ | async"
      (yearChange)="onYearChange($event)"
      (removeCorrection)="removeCorrection($event)"
    ></employee-plusmin-overview>
  </div>`,
})
export class MyPlusMinusComponent implements OnInit {
  public stats: Observable<any>;
  public user: Observable<any> = this.getUser();
  public year$ = new BehaviorSubject(getISOWeekYear(new Date()));
  public absenteeOptions = this.store.select(getAbsenteeOptions);
  public loadingCorrections = true;
  public loadingStats = true;

  public showAbsence$ = this.showAbsence();

  constructor(
    private store: Store<AppState>,
    private correctionService: CorrectionService,
  ) {}

  ngOnInit() {
    this.stats = observableCombineLatest(this.user, this.year$).pipe(
      switchMap(([user, year]) => this.getPlusMinStats(user.id, year)),
    );
  }

  private getUser() {
    return this.store.select(getAuthenticatedUser).pipe(
      map((employee: EmployeeModel) => ({
        id: employee.id,
        start: format(parseDate(employee.startdate), 'yyyy'),
      })),
    );
  }

  private showAbsence() {
    return this.store.select(getAuthenticatedUserId).pipe(
      withLatestFrom(this.store.select(getPermissionState), this.store.select(getEmployeeTeamDepartments)),
      map(([employeeId, permissionState, employeeTeamDepartments]) =>
        hasPermission(
          {
            userId: employeeId,
            permissions: ['View absentee', 'View own absentee'],
            departments: employeeTeamDepartments[employeeId],
          },
          permissionState,
        ),
      ),
    );
  }

  private getPlusMinStats(userId, year) {
    this.loadingStats = true;

    const yearStart = format(weekYearStart(year), 'yyyy-MM-dd');
    const yearEnd = format(weekYearEnd(year), 'yyyy-MM-dd');

    //load corrections
    const correction$ = this.getCorrections(userId, yearStart, yearEnd);

    return observableCombineLatest(
      this.correctionService.loadEmployeePlusMinStats(userId, yearStart, yearEnd, 'week').pipe(
        startWith(null),
        tap((data) => {
          if (data) {
            this.loadingStats = false;
          }
        }),
        repeatWhen(() => this.correctionService.listenToChanges()),
      ),
      this.correctionService.loadEmployeePlusMinStats(userId, yearStart, yearEnd, 'day').pipe(
        startWith(null),
        repeatWhen(() => this.correctionService.listenToChanges()),
      ),
      correction$,
      mapStats,
    );
  }

  private getCorrections(userId, yearStart: string, yearEnd: string) {
    const correctionRequest: LoadCorrectionRequest = {
      user_id: userId,
      min_date: yearStart,
      max_date: yearEnd,
      type: 'Overtime',
    };

    this.correctionService.load(correctionRequest).subscribe(() => (this.loadingCorrections = false));

    return this.store.select(getUserCorrections(userId, yearStart, yearEnd, correctionRequest.type));
  }

  onYearChange(year) {
    this.year$.next(year);
  }

  removeCorrection(correctionId) {
    this.correctionService.remove(correctionId).subscribe();
  }
}
