<div class="flex h-14 items-center justify-between bg-primary-500 pl-3 font-semibold text-white">
  <div class="sidebar__nav-header--title sidebar__nav-header--toggleable pr-[10px]" (click)="toggle()">
    {{ 'Widgets' | translate }}
  </div>
  <div class="sidebar__nav-header--close">
    <button sb-button [color]="'primary'" (click)="close()" class="sb-button-responsive-label">
      {{ 'Close' | translate }}
      <sb-icon iconPositionEnd [name]="'chevron-right'"></sb-icon>
    </button>
  </div>
</div>

<div class="sidebar__content">
  <div class="sidebar__element-container" *ngFor="let control of controlsOrder">
    <label class="checkbox">
      <input type="checkbox" class="checkbox-input" [formControl]="checkboxesFormGroup.controls[control]" />
      <span class="checkbox-indicator"></span>
      <span class="checkbox-description text-word-mark-800">{{ control | translate }}</span>
    </label>
  </div>
</div>
