import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: '[navigationItem]',
  template: `
    <a
      class="header__navbar-link"
      [ngClass]="{ 'menu-locked': locked }"
      [routerLink]="locked ? null : link"
      (click)="clicked()"
      [routerLinkActive]="locked ? '' : 'active'"
    >
      <icon class="header__navbar-link-icon" [svgIcon]="locked ? 'lock' : icon"></icon>
      {{ label }}
    </a>
  `,
  styleUrls: ['navigation-item.component.scss'],
})
export class NavigationItemComponent {
  @Input()
  public link: string[] | string;
  @Input()
  public icon: string;
  @Input()
  public label: string;
  @Input()
  public locked: boolean;
  @Output()
  public lockClick = new EventEmitter();

  @HostBinding('class')
  private classes = 'header__navbar-item';

  public clicked() {
    if (!this.locked) {
      return;
    }

    this.lockClick.emit();
  }
}
