<rModal>
  <div class="modal-header">
    <div class="modal-title">{{ 'Favorite' }}</div>
    <a class="btn btn-clear modal-close" (click)="close()">
      <icon class="si modal-close__icon" svgIcon="close"></icon>
    </a>
  </div>

  <div class="modal-body">
    <modal-field [control]="nameCtrl" [label]="'Name' | translate">
      <input class="form-modal__input" type="text" [formControl]="nameCtrl" />
    </modal-field>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="close()">{{ 'Cancel' | translate }}</button>
    <button [ladda]="isLoading" (click)="save()" type="submit" class="btn btn-primary">{{ 'Save' | translate }}</button>
  </div>
</rModal>
