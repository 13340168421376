import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { introductionModalConfig, IntroModalType } from '@app/+modals/introduction';
import { IntroductionModalComponent } from '@app/+modals/introduction/introduction-modal.component';
import { BoldPipe } from '@app/pipes/bold.pipe';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { AppState } from '@app/reducers';
import { AuthService, getAuthenticatedUserEmail } from '@app/reducers/auth/auth.service';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { HubSpotService } from '@app/startup/hub-spot.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AnchorButtonComponent, ButtonComponent, IconButtonComponent, IconComponent } from '@sb/ui';
import { CookieService } from 'ngx-cookie-service';
import { DialogService } from 'primeng/dynamicdialog';
import { distinctUntilChanged, map, Observable } from 'rxjs';

@Component({
  selector: 'verification-banner',
  templateUrl: './verification-banner.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BoldPipe,
    IntroductionModalComponent,
    RouterModule,
    TranslationParamsPipe,
    FeatureFlagPipe,
    ButtonComponent,
    IconButtonComponent,
    IconComponent,
    AnchorButtonComponent,
  ],
})
export class VerificationBannerComponent implements OnInit {
  public authUser$ = this.store.select(getAuthenticatedUser);
  public email$ = this.store.select(getAuthenticatedUserEmail);
  public isOnSettingsPage$: Observable<boolean> = this.router.events.pipe(
    distinctUntilChanged(),
    map(
      () =>
        this.router.url.includes('settings') ||
        this.router.url.includes('billing') ||
        this.router.url.includes('integrations'),
    ),
  );

  public isChatActive = false;

  private readonly visible = signal(true);
  private readonly isOnSettingsPage = toSignal(this.isOnSettingsPage$);
  public readonly hideBanner = computed(() => !this.visible() || this.isOnSettingsPage());

  public constructor(
    private store: Store<AppState>,
    public router: Router,
    private dialogService: DialogService,
    private authService: AuthService,
    private cookieService: CookieService,
    private hubspotService: HubSpotService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    // todo: hubspot chat service should be updated so we do not need to use cookie service to know if chat is enabled or not
    // it should be a part of the service and simply be a signal or observable
    void this.hubspotService.isChatEnabled$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((response) => {
      this.isChatActive = response;
    });
    this.isChatActive = this.cookieService.get('hubspotChatVisible') === 'true';
  }

  public resendEmail(): void {
    void this.authService
      .sendVerificationMail()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.dialogService.open(IntroductionModalComponent, {
            data: {
              introModalType: IntroModalType.BANNER_OVERLAY,
            },
            ...introductionModalConfig,
          });
        },
      });
  }

  public onClose(): void {
    this.visible.set(false);
  }
}
