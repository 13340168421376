import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RouteModalComponent } from '../../../route-modal.component';
import { ReportFavoriteService } from '../../../../reducers/orm/report-favorites/report-favorite.service';
import { ReportService } from '../../../../reducers/orm/reports/report.service';

@Component({
  selector: 'modal-report-favorite',
  templateUrl: './report-favorite-modal.component.html',
})
export class ReportFavoriteModalComponent implements OnInit {
  @ViewChild(RouteModalComponent, { static: true })
  private modal: RouteModalComponent;

  public isLoading: boolean;

  public nameCtrl = new UntypedFormControl('', [Validators.required]);

  public constructor(private reportFavoriteService: ReportFavoriteService, private reportService: ReportService) {}

  public ngOnInit() {
    const data = this.reportService.getReportParameters();

    if (!data) {
      this.close();
    }
  }

  public close() {
    this.reportService.reportParametersWithType.next(null);
    this.modal.close();
  }

  public save() {
    if (!this.nameCtrl.valid) {
      return;
    }

    this.isLoading = true;

    const favoriteToSave = this.reportService.getReportParameters();

    this.reportFavoriteService.saveFavorite({ ...favoriteToSave, name: this.nameCtrl.value }).subscribe({
      next: () => {
        this.close();
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
