import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DashboardFilterType } from '@app/reducers/page-filters/page-filters-dashboard.config';
import { selectAllowedDashboardFilter } from '@app/reducers/page-filters/page-filters-dashboard.selectors';
import { select, Store } from '@ngrx/store';
import { addWeeks } from 'date-fns';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { fieldChangeWithStart } from '../../../../../forms/form.helper';
import { AppState } from '../../../../../reducers/index';
import { PageFiltersService } from '../../../../../reducers/page-filters/page-filters.service';
import { format } from '../../../../../shared/date.helper';
import { getEmployeeBirthdaysInPeriod } from '../widget.helper';

@Component({
  selector: 'upcoming-birthdays-widget',
  templateUrl: './upcoming-birthdays-widget.component.html',
})
export class UpcomingBirthdaysWidgetComponent implements OnInit, OnDestroy {
  public period: UntypedFormControl = new UntypedFormControl('1');
  public isLoading = false;
  public birthdays = [];
  private dataSubs = new Subscription();

  private readonly filterType = DashboardFilterType.BIRTHDAYS;

  public constructor(
    private store: Store<AppState>,
    private pageFilterService: PageFiltersService,
  ) {}

  public ngOnInit() {
    this.isLoading = true;
    this.dataSubs.add(
      this.store.pipe(select(selectAllowedDashboardFilter(this.filterType))).subscribe((dashboardFilter) => {
        const filterVal = dashboardFilter.widgetPeriod;
        if (filterVal) {
          this.period.setValue(filterVal);
        }
      }),
    );

    this.dataSubs.add(
      this.period.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.pageFilterService.setFilter('dashboard', this.filterType, {
          widgetPeriod: this.period.value,
        });
      }),
    );

    this.dataSubs.add(
      fieldChangeWithStart(this.period)
        .pipe(switchMap((period) => this.getEmployeeBirthdaysForPeriod(period)))
        .subscribe((birthdays) => {
          this.birthdays = birthdays;
          this.isLoading = false;
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  private getEmployeeBirthdaysForPeriod(period) {
    const minDate = format(new Date(), 'yyyy-MM-dd');
    const maxDate = format(addWeeks(new Date(), period), 'yyyy-MM-dd');
    return this.store.select(getEmployeeBirthdaysInPeriod(minDate, maxDate));
  }
}
