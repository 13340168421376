<nav class="flex items-center">
  <div
    class="flex gap-[10px] list-none m-0 p-0"
    [ngClass]="{
      'flex-row': !isMobile,
      'flex-col': isMobile,
      'w-full': isMobile
    }"
    (click)="onClick()"
  >
    <sb-nav-item [isMobile]="isMobile" link="/dashboard" [label]="'Dashboard' | translate"></sb-nav-item>

    <sb-nav-item [isMobile]="isMobile" link="/news" [label]="'News' | translate"></sb-nav-item>

    <sb-nav-item [isMobile]="isMobile" [link]="scheduleLink" [label]="'Schedule' | translate"></sb-nav-item>

    <sb-nav-item
      *permission="['View all timesheets', 'View own timesheet']; department: 'any'; user: 'me'; check: 'any'"
      [isMobile]="isMobile"
      [link]="timesheetLink"
      [label]="'Timesheet' | translate"
    ></sb-nav-item>

    <sb-nav-item
      [isMobile]="isMobile"
      *permission="'View log'"
      link="/diary"
      [label]="'Diary' | translate"
    ></sb-nav-item>

    <ng-container *ngIf="sbPlanDialogService.isLocked | async">
      <sb-nav-item
        [isMobile]="isMobile"
        [locked]="true"
        link="/diary"
        [label]="'Diary' | translate"
        (lockClick)="openLockModal(lockType.DIARY)"
      ></sb-nav-item>
    </ng-container>

    <sb-nav-item
      [isMobile]="isMobile"
      *permission="'View all users'"
      link="/employees"
      [label]="'Employees' | translate"
    ></sb-nav-item>

    <sb-nav-item
      [isMobile]="isMobile"
      *permission="'View reports'; department: 'any'"
      link="/reports"
      [label]="'Reports' | translate"
    ></sb-nav-item>
  </div>
</nav>
