import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeRight } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { filter, map, Subscription } from 'rxjs';

import { AppState } from '../../../reducers';
import { Message } from '../../../reducers/message/message.model';
import { getMessages } from '../../../reducers/message/message.service';

@Component({
  selector: 'app-notifications',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p-toast [preventOpenDuplicates]="true" position="bottom-left" (onClose)="onReject()" [baseZIndex]="1102"></p-toast>
  `,
  providers: [MessageService],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private dataSubs = new Subscription();

  public constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
  ) {}

  public ngOnInit(): void {
    this.dataSubs.add(
      this.store
        .select(getMessages)
        .pipe(
          map((messages) => takeRight(messages, 1)),
          filter((messages) => messages.length > 0),
        )
        .subscribe((messages: Message[]) => {
          this.messageService.addAll([{ severity: messages[0].type, detail: messages[0].message }]);
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public onReject() {
    this.messageService.clear('c');
  }
}
