import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Input, ViewChild } from '@angular/core';

import { EmployeeModel } from '../../../../reducers/orm/employee/employee.model';
import { SBHeaderService } from '../sb-header.service';
import { SBProfileDropdownComponent } from '../sb-profile-dropdown/sb-profile-dropdown';
import { SBAvatarComponent } from './../../../../shared/sb-lib/avatar/sb-avatar.component';

@Component({
  selector: 'sb-header-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgStyle, NgClass, AsyncPipe, SBAvatarComponent, SBProfileDropdownComponent],
  template: `<div
    #root
    class="group flex h-full w-[50px] cursor-pointer items-center justify-center"
    (click)="headerService.toggleProfileDropdownOpen()"
  >
    <div class="relative rounded-full p-1 group-hover:bg-grey-100">
      <sb-avatar [employee]="employee" [size]="size"> </sb-avatar>
      <div
        *ngIf="notificationCount > 0"
        class="absolute right-[-4px] top-[-4px] overflow-hidden rounded-full bg-white p-[2px]"
        [ngStyle]="{ width: size * 0.7 + 'px', height: size * 0.7 + 'px' }"
      >
        <div
          class="flex h-5 min-w-5 items-center justify-center rounded-full bg-error-500 p-1 font-bold text-white"
          [ngStyle]="{ 'font-size': size * 0.35 + 'px' }"
        >
          {{ notificationCount }}
        </div>
      </div>
    </div>
  </div>`,
})
export class SBHeaderAvatarComponent {
  @Input()
  public employee: EmployeeModel;
  @Input()
  public size: number;
  @Input()
  public notificationCount: number;

  @ViewChild('root')
  public rootRef;

  @HostListener('document:click', ['$event'])
  public clickout(event) {
    if (!open || this.rootRef.nativeElement.contains(event.target)) {
      return;
    }

    this.headerService.profileDropdownOpen$.next(false);
  }

  public constructor(public headerService: SBHeaderService) {}
}
