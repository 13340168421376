import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@sb/ui';

@Component({
  selector: 'app-downgrade-intro',
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslationParamsPipe, ButtonComponent],
  templateUrl: './downgrade-intro.component.html',
})
export class DowngradeIntroComponent {
  @Input()
  public planName: string;

  @Output()
  public incrementStep = new EventEmitter<void>();
}
