<h1 class="text-4xl font-extrabold text-word-mark-800">
  {{ 'Remove Departments And/Or Teams From Your Organization' | translate }}
</h1>
<div class="text-xl font-medium text-word-mark-700">
  {{
    "Select which items you'd like to remove, so that you can fit within your [planName] plan, then we will help you transfer the employees."
      | translate
      | translationParams: { planName: planName }
  }}
</div>
<div *ngIf="subscriptionPlanQuotas[quotaType.DEPARTMENTS] || subscriptionPlanQuotas[quotaType.TEAMS]" class="card">
  <div
    class="flex flex-col p-4"
    [ngClass]="{ 'gap-2': subscriptionPlanQuotas[quotaType.DEPARTMENTS] && subscriptionPlanQuotas[quotaType.TEAMS] }"
  >
    <app-quota-card-rule
      [quotaType]="quotaType.DEPARTMENTS"
      [subscriptionPlanQuotas]="subscriptionPlanQuotas"
    ></app-quota-card-rule>
    <app-quota-card-rule
      [quotaType]="quotaType.TEAMS"
      [subscriptionPlanQuotas]="subscriptionPlanQuotas"
    ></app-quota-card-rule>
  </div>
</div>
<div class="mb-32">
  <locations-table></locations-table>
</div>
<div
  class="fixed bottom-0 left-0 flex h-full max-h-32 w-full flex-col items-center justify-center gap-4 border-0 border-t border-solid border-grey-100 bg-white shadow-2xl"
>
  <button
    sb-button
    [disabled]="
      subscriptionPlanQuotas[quotaType.DEPARTMENTS]?.usage > subscriptionPlanQuotas[quotaType.DEPARTMENTS]?.limit ||
      subscriptionPlanQuotas[quotaType.TEAMS]?.usage > subscriptionPlanQuotas[quotaType.TEAMS]?.limit
    "
    [color]="'primary'"
    class="w-full max-w-2xl"
    [block]="true"
    (click)="continue()"
  >
    {{ 'Continue' | translate }}
  </button>
  <p class="mb-0 font-semibold text-word-mark-800">
    {{ ' Changed your mind? ' | translate }}
    <a
      [routerLink]="[
        '',
        { outlets: { modal: ['billing', 'plan-wizard', { originator: 'schedule-downgrade-quota-flow' }] } }
      ]"
      [queryParams]="{ upgrade: true }"
    >
      <span class="text-primary-500">
        {{ 'Upgrade your plan' | translate }}
      </span>
    </a>
  </p>
</div>
