<div class="dash-block">
  <div class="dash-block__header">
    <div class="dash-block__title" translate>Weather forecast</div>
  </div>
  <div class="dash-block__body">
    <content-state
      [isLoading]="isLoading"
      [isEmpty]="departmentForecasts.length === 0"
      icon="wi-day-rain"
      size="medium-small"
      content="{{ 'No weather forecasts available' | translate }}"
    >
      <table class="table-widget table-uniform-rows">
        <thead>
          <tr>
            <th translate>Name</th>
            <ng-container *ngFor="let date of dates; index as i">
              <th class="weather-day-{{ i }}" style="width: 50px">{{ date | date: 'eeeeee d' }}</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let departmentForecast of departmentForecasts">
            <td style="padding: 0 12px">
              {{ departmentForecast.location_name }}<br />{{ departmentForecast.department_name }}
            </td>
            <ng-container *ngFor="let forecast of departmentForecast.forecast; index as i">
              <td class="weather-forecast-day-{{ i }}" style="padding: 7px 0">
                <div>
                  <icon class="si-weather" [svgIcon]="'wi-' + forecast.weather_type"></icon>
                  <br />{{ forecast.temp_min_celcius }}&#176;/{{ forecast.temp_max_celcius }}&#176;
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
