<rModal>
  <div class="modal-header">
    <div class="modal-title">{{ 'Schedule report' | translate }}</div>
    <a (click)="close()" class="btn btn-clear modal-close">
      <icon class="si modal-close__icon" svgIcon="close"></icon>
    </a>
  </div>
  <form (ngSubmit)="save()" [formGroup]="form" novalidate>
    <div class="modal-body">
      <modal-field [control]="form.get(['periodSettings', 'type'])" [label]="'Report period' | translate">
        <select [formControl]="form.get(['periodSettings', 'type'])" class="form-modal__input select">
          <option *ngFor="let period of recurringPeriod" [value]="period.value">{{ period.label }}</option>
        </select>
      </modal-field>

      <modal-field [control]="form.get(['recurrenceSettings', 'interval'])" [label]="'Run report' | translate">
        <select [formControl]="form.get(['recurrenceSettings', 'interval'])" class="form-modal__input select">
          <option *ngFor="let interval of recurringInterval" [value]="interval.value">{{ interval.label }}</option>
        </select>
      </modal-field>

      <ng-container *ngIf="form.get(['recurrenceSettings', 'dayOfWeek']).enabled">
        <modal-field [control]="form.get(['recurrenceSettings', 'dayOfWeek'])" [label]="'On' | translate">
          <select [formControl]="form.get(['recurrenceSettings', 'dayOfWeek'])" class="form-modal__input select">
            <option *ngFor="let week of recurringDayOfWeek" [value]="week.value">{{ week.label }}</option>
          </select>
        </modal-field>
      </ng-container>

      <modal-field [control]="form.get(['recurrenceSettings', 'runTime'])" [label]="'Run time' | translate">
        <time-input [formControl]="form.get(['recurrenceSettings', 'runTime'])"></time-input>
      </modal-field>

      <hr />
      <modal-field [control]="form.get('name')" [label]="'Report Name' | translate">
        <input [formControl]="form.get('name')" class="form-modal__input" type="text" />
      </modal-field>

      <ng-container *ngIf="projections?.length">
        <div class="py-4 px-2 upcoming-reports-box flex flex-column">
          <span class="font-bold">{{ 'Upcoming reports' | translate }}:</span>
          <ng-container *ngFor="let projection of projections">
            <span class="font-semibold">{{
              'Runs on [runTime] for the period [startDate] - [endDate]'
                | translate
                | translationParams
                  : {
                      runTime: projection.runTime | localeDate: intlFormatType.DATETIME,
                      startDate: projection.reportPeriod.startDate | localeDate,
                      endDate: projection.reportPeriod.endDate | localeDate
                    }
            }}</span>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button (click)="close()" class="btn btn-secondary" type="button">{{ 'Cancel' | translate }}</button>
      <button [ladda]="isLoading" class="btn btn-primary" type="submit">
        {{ 'Save' | translate }}
      </button>
    </div>
  </form>
</rModal>
