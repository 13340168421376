import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!req.url.startsWith('/api/')) {
      return next.handle(req);
    }

    // 'GET' and 'OPTIONS' requests are not protected by CSRF
    if (req.method === 'GET') {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-SHIFTBASE-CSRF-PROTECTION': '1',
        },
      }),
    );
  }
}
