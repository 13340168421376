@if (openShifts?.length > 0) {
  <sidebar-header [title]="'Open Shifts' | translate" [indicator]="openShifts.length" />
  @for (openShift of openShifts; track $index) {
    <div sidebarRequestItem>
      <sb-summary-item class="cursor-pointer" [compact]="true" (click)="showOpenShift(openShift.occurrence_id)">
        <span firstRowLeft class="truncate"> {{ openShift.location_name }} - {{ openShift.department_name }} </span>
        <span secondRowLeft>
          {{ openShift.date | date: 'dd MMM yy' }}: {{ openShift.starttime | time }}
          @if (!openShift.hide_end_time) {
            - {{ openShift.endtime | time }}
          }
        </span>
      </sb-summary-item>
    </div>
  }
}
