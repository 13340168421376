<div class="flex h-full flex-col overflow-y-scroll">
  <div
    class="fixed z-navbar flex h-navbar w-full items-center justify-between gap-3 border-0 border-b border-solid border-grey-200 bg-white p-3"
  >
    <div class="h-full">
      <img class="h-full" src="/assets/images/shiftbase_logo_dark.svg" />
    </div>

    <sb-header-avatar
      class="h-full"
      [employee]="authenticatedUser"
      [size]="30"
      [notificationCount]="0"
    ></sb-header-avatar>
  </div>
  <sb-profile-dropdown [open]="profileDropdownOpen$ | async"></sb-profile-dropdown>

  <div class="mt-navbar flex flex-1 items-center justify-center bg-grey-100 p-3">
    <div
      class="flex w-96 max-w-full flex-col items-center gap-6 rounded-3xl border border-solid border-grey-200 bg-white p-10"
    >
      <div class="flex rounded-xl bg-primary-50 p-6 text-primary-500">
        <sb-icon [name]="'stopwatch'" [size]="'2xl'"></sb-icon>
      </div>
      <div class="flex flex-col gap-4 text-center">
        <h4 class="text-2xl font-bold text-grey-800">
          {{ 'Welcome back [firstName]!' | translate | translationParams: { firstName: authenticatedUser.first_name } }}
        </h4>
        <p class="text-grey-500">
          {{ 'Your subscription is currently paused.' | translate }}
          @if (canManageAccount) {
            <span>{{ 'Resume your subscription below.' | translate }}</span>
          } @else {
            <span>{{ 'To resume your subscription please contact the account manager.' | translate }}</span>
          }
        </p>
      </div>
      @if (canManageAccount) {
        <button sb-button [color]="'primary'" (click)="resumeSubscription()" [sbLadda]="loading">
          {{ 'Resume subscription' | translate }}
        </button>
      }
    </div>
  </div>
</div>
