<div class="page" [ngClass]="{ 'p-0': removeDashboardPadding }">
  <sb-sidebar [menuItems]="menuItems" [title]="'My' | translate" (onLocked)="handleOnLock($event)"> </sb-sidebar>
  <div class="w-full min-w-1">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>
<ng-template #announcementContent>
  <p>
    {{
      'We’re excited to introduce Open-ended Absences, a new feature designed to make managing employee sick days easier. When an employee calls in absent with an unknown return date, you can now log their absence without needing to estimate their return. Once they are back you can adjust the end-date.'
        | translate
    }}
  </p>
  <strong>{{ 'What’s new?' | translate }}</strong>
  <ul>
    <li>{{ 'Easily create Absences without a set end-date' | translate }}</li>
    <li>{{ 'View and manage these Absences directly from your schedule' | translate }}</li>
    <li>{{ 'Update the end-date when the employee returns' | translate }}</li>
  </ul>
</ng-template>
