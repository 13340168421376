<div class="flex h-full flex-col items-center justify-center bg-grey-25">
  <div
    class="w-full {{
      mode === 'manager' ? 'md:w-[800px]' : 'md:w-[500px]'
    }} bg-white rounded-lg shadow-xl overflow-hidden"
    [ngClass]="{ anonymous__box__large: mode === 'manager' }"
    *ngIf="mode"
  >
    <div class="padding-[12px] flex h-[60px] items-center justify-center bg-primary-500 text-center text-white">
      <icon class="h-[30px] w-[180px]" svgIcon="shiftbase-logo-text"></icon>
    </div>

    <div class="p-6" *ngIf="mode === 'employee'">
      <h3 class="anonymous__title">{{ 'Your account has been locked' | translate }}</h3>

      <p>{{ 'Your account is temporarily unavailable and cannot be accessed.' | translate }}</p>
    </div>

    <div class="p-6" *ngIf="mode === 'manager'">
      <h3 class="anonymous__title" translate>Your account has been locked because of overdue payments</h3>

      <p translate>
        Your account is temporarily unavailable and cannot be accessed because of overdue payments. This page shows a
        list of expired invoices. These invoices can be paid by using the links below. Processing of these invoices can
        take some time, after which access to your account will be restored.
      </p>

      <div class="card">
        <div class="card-heading">
          <div class="card-heading__title" translate>Invoice overview</div>
        </div>
        <div class="card-table">
          <table class="table-border">
            <thead>
              <tr>
                <th></th>
                <th translate>Invoice number</th>
                <th translate>Date</th>
                <th translate>Expiration date</th>
                <th translate>Total amount</th>
                <th translate>Access code</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoice of unpaidInvoices">
                <td></td>
                <td>{{ invoice.invoice_number }}</td>
                <td>{{ invoice.created }}</td>
                <td>{{ invoice.due_date }}</td>
                <td>
                  {{ invoice.total | money: null : null : { currencyCode: invoice?.currency_code } }}
                </td>
                <td>{{ invoice.access_code }}</td>
                <td *ngIf="invoice.status !== 'uncollectible'">
                  <button
                    (click)="openInvoice(invoice)"
                    [ladda]="isInvoicePaymentPageLoading[invoice.id]"
                    class="btn btn-primary float-right"
                  >
                    {{ 'View and pay' | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-6 text-center text-grey-400">
    <a (click)="backToLogin()">{{ 'Back to login' | translate }}</a>
  </div>
</div>
