import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, flatMap } from 'rxjs/operators';

import { authState } from '../../reducers/auth/auth.service';
import { AppState } from '../../reducers/index';

@Injectable()
export class XurfInterceptor implements HttpInterceptor {
  public constructor(private store: Store<AppState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.startsWith('/api/')) {
      return next.handle(req);
    }

    return this.store.select(authState).pipe(
      first(),
      flatMap((authenticatedState) => {
        if (!authenticatedState.authenticated || !authenticatedState.user_id || req.url.includes('ipify')) {
          return next.handle(req);
        }

        const xurfRequest = req.clone({
          setHeaders: {
            'X-App-Account': authenticatedState.account_id,
            'X-App-User': authenticatedState.user_id,
          },
        });

        return next.handle(xurfRequest);
      }),
    );
  }
}
