import { take, switchMap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/reducers';
import { AccountFile, AccountModel } from '@app/reducers/account/account.model';
import { AccountService, getAccountFiles, getAccount } from '@app/reducers/account/account.service';
import { trackByIdFn } from '@app/shared/trackby';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import {
  ContentHeaderComponent,
  ContentNavDirective,
  ContentNavItemComponent,
} from '@app/+authenticated/shared/content-header/content-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentStateComponent } from '@app/shared/content-state/content-state.component';
import { NgForOf, NgIf } from '@angular/common';
import { PermissionCheckPipe } from '@app/pipes/permission-flag.pipe';
import { PlanCheckPipe } from '@app/pipes/plan-check.pipe';
import { RouterModule } from '@angular/router';
import { ActionConfirmComponent } from '@app/+authenticated/shared/action-confirm.component';
import { IconComponent } from '@app/+authenticated/shared/icon.component';

@Component({
  selector: 'files',
  templateUrl: './files.component.html',
  styles: [
    `
      :host {
        @apply flex;
      }
    `,
  ],
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    TranslateModule,
    RouterModule,
    IconComponent,
    ContentHeaderComponent,
    ContentStateComponent,
    ContentNavItemComponent,
    ActionConfirmComponent,
    PermissionCheckPipe,
    PlanCheckPipe,
    ContentNavDirective,
  ],
})
export class FilesComponent implements OnInit, OnDestroy {
  public planType = PlanType;
  public files: AccountFile[];
  public account: AccountModel;

  private subscription = new Subscription();
  public loading = true;

  public trackById = trackByIdFn;

  constructor(private store: Store<AppState>, private accountService: AccountService) {}

  ngOnInit() {
    this.subscription.add(
      this.accountService
        .loadFiles()
        .pipe(
          take(1),
          switchMap(() => combineLatest(this.store.select(getAccountFiles), this.store.select(getAccount)))
        )
        .subscribe(([files, account]) => {
          this.account = account;
          this.files = files;
          this.loading = false;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  download(file: AccountFile) {
    this.accountService.download(file.id, file.file_name).subscribe();
  }

  delete(file: AccountFile) {
    this.accountService.deleteFile(file.id).subscribe();
  }
}
