import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getAuthenticatedStatus } from '@app/reducers/auth/auth.service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppState } from '../../reducers';

@Injectable()
export class PausedGuard {
  public constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean> | boolean {
    return this.store.select(getAuthenticatedStatus).pipe(
      switchMap((authStatus) => {
        if (authStatus === 'paused') {
          return of(true);
        } else {
          this.router.navigate(['/dashboard']);
          return of(false);
        }
      }),
    );
  }
}
