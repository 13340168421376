import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../../icon.component';

@Component({
  selector: 'sb-header-upgrade-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, RouterModule, AsyncPipe, TranslateModule],
  template: ` <div class="group flex h-full cursor-pointer items-center justify-center px-3">
    <div
      class="flex select-none items-center justify-between rounded-[5px] px-2 py-1.5 text-base font-semibold text-grey-800 group-hover:bg-grey-100"
    >
      <div class="flex flex-row items-center justify-center gap-2">
        <icon class="h-[1.3rem]  w-[1.3rem] text-warning-400" svgIcon="upgrade-bolt"></icon>
        <span class="truncate">{{ buttonText }}</span>
      </div>
    </div>
  </div>`,
})
export class SBHeaderUpgradeButtonComponent {
  @Input()
  public buttonText: string;

  public constructor() {}
}
