<div class="dash-block">
  <div class="dash-block__header">
    <div class="dash-block__title" translate>Birthdays</div>
    <div class="dash-block__select-header form-group flex items-center">
      <label for="show-period" class="col-form-label p-0 m-0" translate>Period</label>&nbsp;
      <select class="select-small" [formControl]="period" id="show-period">
        <option value="1" translate>1 week</option>
        <option value="2" translate>2 weeks</option>
        <option value="3" translate>3 weeks</option>
        <option value="4" translate>4 weeks</option>
      </select>
    </div>
  </div>
  <div class="dash-block__body">
    <content-state
      [isLoading]="isLoading"
      [isEmpty]="birthdays.length === 0"
      icon="user"
      size="extra-small"
      content="{{ 'No birthdays were found for this option' | translate }}"
    >
      <table class="table-widget table-uniform-rows">
        <thead>
          <tr>
            <th class="row-50" translate>Name</th>
            <th class="row-20" translate>Date</th>
            <th class="row-30" translate>New age</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let birthday of birthdays">
            <td>{{ birthday.name }}</td>
            <td>{{ birthday.birthday | date: 'PP' }}</td>
            <td>{{ birthday.birthday_age }}</td>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
