import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class FallBackTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return params.translateService.parser.interpolate(params.key, params.interpolateParams);
    }

    return params.key;
  }
}
