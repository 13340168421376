import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sidebarRequestItem]',
  standalone: true,
})
export class SidebarRequestItemDirective {
  @HostBinding('class')
  public readonly class = clsx('border-0 border-b-2 border-solid border-grey-200 px-3 py-4 text-word-mark-800');
}
