<div class="dash-block">
  <div class="dash-block__header">
    <div class="dash-block__title" translate>Ending contracts</div>
    <div class="dash-block__select-header form-group flex items-center">
      <label for="show-period" class="col-form-label p-0 m-0" translate>Period</label>&nbsp;
      <select class="select-small" [formControl]="period" id="show-period">
        <option value="1">1 {{ 'month' | translate }}</option>
        <option value="2">2 {{ 'months' | translate }}</option>
        <option value="3">3 {{ 'months' | translate }}</option>
        <option value="4">4 {{ 'months' | translate }}</option>
      </select>
    </div>
  </div>
  <div class="dash-block__body">
    <content-state
      [isLoading]="isLoading"
      [isEmpty]="employeesWithContracts.length === 0"
      icon="clipboard"
      size="extra-small"
      content="{{ 'No ending contracts were found for this option' | translate }}"
    >
      <table class="table-widget table-uniform-rows">
        <thead>
          <tr>
            <th class="row-40" translate>Name</th>
            <th class="row-30" translate>Contract type</th>
            <th class="row-30" translate>Last workday</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of employeesWithContracts">
            <td>
              <a [routerLink]="['/employees', employee.id, 'contracts']">
                {{ employee.name }}
              </a>
            </td>
            <td>
              {{ employee.contract?.contract_type?.name }}
            </td>
            <td>
              {{ employee.enddate | date: 'PP' }}
            </td>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
