<ul (click)="onClick()" class="header__navbar-nav">
  <!-- leave the empty permission checks... removing this will cause unstyled menu items to appear  -->
  <li navigationItem link="/dashboard" icon="dashboard" [label]="'Dashboard' | translate"></li>
  <li navigationItem link="/news" icon="news" [label]="'News' | translate"></li>
  <li navigationItem [link]="scheduleLink" icon="schedule" [label]="'Schedule' | translate"></li>
  <ng-container *ngIf="!hasAtMostFree || (sbPlanDialogService.isLocked | async)">
    <li
      navigationItem
      [locked]="sbPlanDialogService.isLocked | async"
      [link]="timesheetLink"
      icon="timesheets"
      [label]="'Timesheet' | translate"
      (lockClick)="openLockModal(lockType.TIMESHEET)"
    ></li>
  </ng-container>
  <li *permission="'View log'" navigationItem link="/diary" icon="diary" [label]="'Diary' | translate"></li>

  <ng-container *ngIf="sbPlanDialogService.isLocked | async">
    <li
      [locked]="true"
      navigationItem
      link="/diary"
      icon="diary"
      [label]="'Diary' | translate"
      (lockClick)="openLockModal(lockType.DIARY)"
    ></li>
  </ng-container>
  <li
    *permission="'View all users'"
    navigationItem
    link="/employees"
    icon="employees"
    [label]="'Employees' | translate"
  ></li>

  <ng-container
    *ngIf="sbPlanDialogService.isLocked | async; else permCheck"
    [ngTemplateOutlet]="reports"
    [ngTemplateOutletContext]="{ locked: true }"
  ></ng-container>

  <ng-template #permCheck>
    <ng-container
      *permission="'View reports'; department: 'any'"
      [ngTemplateOutlet]="reports"
      [ngTemplateOutletContext]="{ locked: false }"
    ></ng-container>
  </ng-template>
</ul>
<div (click)="onClick()" class="header__navbar-nav__backdrop"></div>

<ng-template #reports let-locked="locked">
  <li
    [locked]="locked"
    navigationItem
    link="/reports"
    icon="reports"
    [label]="'Reports' | translate"
    (lockClick)="openLockModal(lockType.REPORT)"
  ></li>
</ng-template>
