import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError as observableThrowError, timer as observableTimer } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AppState } from '../../reducers/index';
import { MessageAction } from '../../reducers/message/message.action';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(
    private store: Store<AppState>,
    private router: Router,
    private injector: Injector,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestPath = request.url;

    return next.handle(request).pipe(
      catchError((err: any) => {
        const ignoreMetaMessage = request?.headers.get('ignore-meta-message') === 'true';

        if (ignoreMetaMessage) {
          return observableThrowError(err);
        }

        if (err instanceof HttpErrorResponse) {
          return this.checkError(err, requestPath);
        }

        const translate = this.injector.get(TranslateService);
        this.store.dispatch(MessageAction.error(translate.instant(_('An error has occurred'))));

        return observableThrowError(err);
      }),
    );
  }

  /**
   * Temporary disabled / not used.
   * @param errors
   * @param requestMethod
   */
  private retryFailedRequest(errors: Observable<any>, requestMethod: string): Observable<any> {
    return errors.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        //only try 3 times
        if (retryAttempt > 3) {
          return observableThrowError(error);
        }

        if (!(error instanceof HttpErrorResponse)) {
          return observableThrowError(error);
        }

        if (error.status === 521 || error.status === 522) {
          return observableTimer(i * 100);
        }

        if (error.status === 502 && ['GET', 'PUT', 'DELETE'].includes(requestMethod)) {
          return observableTimer(i * 100);
        }

        if (error.status === 429) {
          return observableTimer(i * 1000);
        }

        return observableThrowError(error);
      }),
    );
  }

  private checkError(err: HttpErrorResponse, requestPath: string): Observable<any> {
    if (err.error && err.error.meta) {
      const { meta } = err.error;
      if (meta.status === 'error' && meta.status_message) {
        this.store.dispatch(MessageAction.error(meta.status_message));
        return observableThrowError(err);
      }
    }

    return this.serverError(err, requestPath);
  }

  private serverError(err: HttpErrorResponse, requestPath: string): Observable<any> {
    const translate = this.injector.get(TranslateService);

    if (err.status === 403) {
      this.store.dispatch(MessageAction.error(translate.instant('You do not have permission for this request')));
      return observableThrowError(err);
    }

    if (err.status === 429) {
      this.store.dispatch(MessageAction.error(translate.instant('Too many requests')));
      return observableThrowError(err);
    }

    if ((err.status === 401 || err.status === 409) && requestPath === '/api/auth/login') {
      return observableThrowError(err);
    }

    // if not logged in / invalid api token
    if (err.status === 401) {
      this.router.navigateByUrl('/logout');
      return observableThrowError(err);
    }

    this.store.dispatch(MessageAction.error(translate.instant('An error has occurred')));
    return observableThrowError(err);
  }
}
