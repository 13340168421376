<h1 class="text-4xl font-extrabold text-word-mark-800">
  {{
    'Your Account Has Been Successfully Downgraded To [planName]!'
      | translate
      | translationParams: { planName: planName }
  }}
</h1>
<div class="text-xl font-medium text-word-mark-700">
  {{
    'To fit within the limits of [planName] and pick up where you left off, you’ll have to update a few things in the organizational structure.'
      | translate
      | translationParams: { planName: planName }
  }}
</div>
<div class="mt-10 flex justify-end">
  <button sb-button [color]="'primary'" class="max-w-fit" [block]="true" (click)="incrementStep.emit()">
    {{ 'Next' | translate }}
  </button>
</div>
