<div class="dash-block">
  <div class="dash-block__header">
    <div class="dash-block__title" translate>My exchanges</div>
    <div class="dash-block__select-header form-group flex items-center">
      <label for="show-period" class="col-form-label p-0 m-0" translate>Period</label>&nbsp;
      <select class="select-small" [formControl]="period" id="show-period">
        <option value="1" translate>1 week</option>
        <option value="2" translate>2 weeks</option>
        <option value="3" translate>3 weeks</option>
        <option value="4" translate>4 weeks</option>
      </select>
    </div>
  </div>
  <div class="dash-block__body">
    <content-state
      [isLoading]="isLoading"
      [isEmpty]="exchanges.length === 0"
      icon="calendar"
      size="extra-small"
      content="{{ 'No exchanges were found for this option' | translate }}"
    >
      <table class="table-widget table-uniform-rows">
        <thead>
          <tr>
            <th class="row-30" translate>Date</th>
            <th class="row-30" translate>Time</th>
            <th class="row-20" translate>Team</th>
            <th class="row-20" translate>Shift</th>
            <th class="row-20"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let exchange of exchanges">
            <td>{{ exchange.date | date: 'PP' }}</td>
            <td>
              <span>{{ exchange.Schedule?.starttime | time }}</span>
              <span *ngIf="!exchange.Schedule?.hide_end_time"> - {{ exchange.Schedule?.endtime | time }}</span>
            </td>
            <td>{{ exchange.Schedule?.Team?.name }}</td>
            <td>{{ exchange.Schedule?.Shift?.long_name }}</td>
            <td>
              <a [routerLink]="['', { outlets: { modal: ['exchange-request', exchange.id] } }]">
                <icon class="si" svgIcon="zoom"></icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
