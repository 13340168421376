import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackingService } from '@app/services/tracking.service';

@Injectable()
export class TrackingInterceptor implements HttpInterceptor {
  public constructor(private readonly trackingService: TrackingService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!req.url.startsWith('/api/') || !this.trackingService.isInitialized || !this.trackingService.anonymousId) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Anonymous-Id': this.trackingService.anonymousId,
        },
      }),
    );
  }
}
