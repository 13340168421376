<div
  class="fixed z-navbar flex h-navbar w-full items-center justify-between border-b border-grey-100 bg-white pr-3"
  [style.border-bottom-style]="'solid'"
>
  <!-- Left for small -->
  <div class="h-full flex-1 xl:hidden">
    <sb-header-hamburger></sb-header-hamburger>
  </div>

  <!-- Center for small -->
  <!-- Left for XL -->
  <div class="flex h-full flex-1 items-center justify-center gap-1 xl:justify-start xl:pl-4">
    <a class="h-full py-3" routerLink="/dashboard/my-overview">
      <img class="h-full" src="/assets/images/shiftbase_logo_dark.svg" />
    </a>
    @if (account.is_beta_account && (Features.TMP_BETA_PROGRAM | featureFlag)) {
      <sb-badge
        class="cursor-pointer"
        [color]="'primary'"
        [sbTooltip]="'Learn more' | translate"
        [sbTooltipPlacement]="'right'"
        (click)="openBetaDialog()"
      >
        BETA
      </sb-badge>
    }
  </div>

  <!-- Center for XL -->
  <sb-nav class="hidden xl:inline-block"></sb-nav>

  <!-- Right -->
  <div class="relative flex h-full flex-1 items-center justify-end">
    <ng-container
      *ngIf="
        hasManageAccountPermission &&
        (!account.continue_subscription || currentSubscription.plan.type === planType.FREE)
      "
    >
      <!-- originator is used for tracking the origin of the user's action to the plan wizard -->
      <sb-header-upgrade-button
        [routerLink]="['/account/billing', { originator: 'btn-header', open: 'plan-wizard' }]"
        [buttonText]="
          !account.continue_subscription && currentSubscription.plan.type !== planType.FREE
            ? ('Confirm plan' | translate)
            : ('Upgrade plan' | translate)
        "
      ></sb-header-upgrade-button>
    </ng-container>

    <a
      sb-header-icon
      [routerLink]="['', { outlets: { modal: ['support'] } }]"
      routerLinkActive
      #rla="routerLinkActive"
      [isActive]="rla.isActive"
      [icon]="'question-circle'"
    >
      {{ 'Help center' | translate }}
    </a>

    @if (hasManageAccountPermission || hasAppCenterPermission) {
      <a
        sb-header-icon
        [icon]="'setting'"
        [routerLink]="
          !hasManageAccountPermission && hasAppCenterPermission ? '/account/integrations' : '/account/settings'
        "
        routerLinkActive
        #rla="routerLinkActive"
        [isActive]="rla.isActive"
      >
        {{ 'Settings' | translate }}
      </a>
    }

    <sb-header-avatar
      class="h-full"
      [employee]="authUser$ | async"
      [size]="30"
      [notificationCount]="totalNotificationCount"
    ></sb-header-avatar>
  </div>

  <sb-profile-dropdown [open]="headerService.profileDropdownOpen$ | async"></sb-profile-dropdown>

  <sidebar class="sidebar" #supervisorRequestsSidebar>
    <supervisor-requests-sidebar></supervisor-requests-sidebar>
  </sidebar>

  <sidebar class="sidebar" #myRequestsSidebar>
    <my-requests-sidebar></my-requests-sidebar>
  </sidebar>
</div>
