import { Injectable, OnDestroy, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  INITIAL_CONFIG,
  IConfig as NgxMaskConfig,
  initialConfig as ngxMaskInitialConfig,
  NgxMaskService,
  provideNgxMask,
} from 'ngx-mask';
import { debounceTime, distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';

const decimalSeparator = (lang: string) => new Intl.NumberFormat(lang, {}).format(1.1).substring(1, 2) as ',' | '.';

const thousandSeparator = (lang: string) => new Intl.NumberFormat(lang, {}).format(1000).substring(1, 2);

export const provideMask = (): Provider[] => [
  {
    provide: INITIAL_CONFIG,
    useFactory: (translate: TranslateService): NgxMaskConfig => ({
      ...ngxMaskInitialConfig,
      validation: false,
      allowNegativeNumbers: false,
      showMaskTyped: false,
      // if we want to support am/pm this would have to be set either depending on lang or settings
      apm: false,
      // use getter to use most recent language since directive will use own instance of the service
      get decimalMarker() {
        return decimalSeparator(translate.currentLang);
      },
      get thousandSeparator() {
        return thousandSeparator(translate.currentLang);
      },
    }),
    deps: [TranslateService],
  },
  provideNgxMask(),
  MaskConfigService,
];

@Injectable()
export class MaskConfigService implements OnDestroy {
  private destroyed$ = new Subject<void>();

  public constructor(
    private translate: TranslateService,
    private maskService: NgxMaskService,
  ) {}

  public init() {
    void this.translate.onLangChange
      .pipe(
        // due to language changing multiple times in a row on initialization, we debounce the event
        debounceTime(3000),
        map((event) => event.lang),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((lang) => {
        this.maskService.thousandSeparator = thousandSeparator(lang);
        this.maskService.decimalMarker = decimalSeparator(lang);
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
