<div
  class="flex h-full flex-col items-center justify-center bg-grey-25"
  [ngClass]="{ 'height-full': mode === 'employee', 'height-auto': mode === 'manager' }"
>
  <div
    class="w-full overflow-hidden rounded-lg bg-white shadow-xl"
    [ngClass]="{
      'md:w-[500px]': mode === 'employee',
      'md:w-[1070px]': mode === 'manager',
      'mt-[60px]': mode === 'manager'
    }"
    *ngIf="mode"
  >
    <div class="padding-[12px] flex h-[60px] items-center justify-center bg-primary-500 text-center text-white">
      <icon class="h-[30px] w-[180px]" svgIcon="shiftbase-logo-text"></icon>
    </div>

    <div class="p-6" *ngIf="mode === 'employee'">
      <h3 class="anonymous__title">{{ 'Thanks for trying' | translate }} {{ accountType }}!</h3>

      <p>
        {{
          'Your free trial has ended. Don’t worry - all your information is safe, let your managerial know if you wish to continue using'
            | translate
        }}
        {{ accountType }}.
      </p>
    </div>

    <div class="h-[92%] overflow-y-auto p-6" *ngIf="mode === 'manager'">
      <ng-container *ngIf="!wizardMode">
        <div class="flex items-center justify-center">
          <div class="flex w-2/4 flex-col items-center justify-center">
            <h3 class="anonymous__title text-center">{{ 'Thanks for trying' | translate }} {{ accountType }}!</h3>

            <p translate class="text-center">
              Your free trial has ended. Don’t worry - all your information is safe. Enter your payment preferences to
              pick up right where you left off.
            </p>
            <a
              (click)="wizardMode = true"
              class="btn btn-primary mb-3 mt-10 flex min-h-[42px] w-10/12 items-center justify-center border-[2px] border-primary-500 bg-white text-primary-500 hover:bg-primary-25"
              type="button"
            >
              <icon class="mr-6 h-[1.3rem] w-[1.3rem] text-warning-400" svgIcon="upgrade-bolt"></icon>
              {{ 'Confirm plan' | translate }}
            </a>
            <span class="text-center text-xs">{{
              'All payments for Shiftbase are handled via Chargebee.' | translate
            }}</span>
          </div>
        </div>
      </ng-container>
      <div *ngIf="wizardMode" class="invoice-settings-container">
        <plan-wizard (restartWizard)="wizardReset()" (exitWizard)="finalize()"></plan-wizard>
      </div>
    </div>
  </div>
  <div class="mt-6 text-center text-grey-400">
    <a (click)="backToLogin()">{{ 'Back to login' | translate }}</a>
  </div>
</div>
