import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanWizardService } from '@app/+authenticated/+account/billing/plan-wizard/plan-wizard.service';
import { SubscriptionService } from '@app/+authenticated/+reports/shared/subscriptions/subscription.service';
import { AccountModel } from '@app/reducers/account/account.model';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../reducers';
import { AccountService, getAccount } from '../../reducers/account/account.service';
import { AuthService } from '../../reducers/auth/auth.service';
import { getPermissionState, hasPermission } from '../../reducers/auth/permission.helper';

@Component({
  selector: 'trial-expired',
  templateUrl: './trial-expired.component.html',
  providers: [PlanWizardService],
})
export class TrialExpiredComponent implements OnInit, OnDestroy {
  public mode: 'manager' | 'employee';
  public accountType: string;
  public account: AccountModel;

  private dataSubs = new Subscription();

  public wizardMode: boolean;

  public constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private accountService: AccountService,
    private subscriptionService: SubscriptionService,
    private planWizard: PlanWizardService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.store.select(getAccount).subscribe({
        next: (account) => {
          this.account = account;
        },
      }),
    );
    this.dataSubs.add(this.subscriptionService.getSubscription().subscribe());
    this.dataSubs.add(
      this.accountService
        .load()
        .pipe(
          tap((response) => {
            this.accountType = response.AccountType.name;
          }),
          switchMap(() => this.store.select(getPermissionState)),
          take(1),
        )
        .subscribe({
          next: (permissionState) => {
            const manageAccount = hasPermission(
              {
                permissions: ['Manage account'],
                userId: 'me',
                departments: 'any',
              },
              permissionState,
            );

            if (manageAccount) {
              this.mode = 'manager';
            } else {
              this.mode = 'employee';
            }
          },
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public backToLogin() {
    this.authService.logout().then(() => {
      this.router.navigate(['login']);
    });
  }

  public redirectToDashboard(status) {
    if (status) {
      const intendedRoute = this.authService.redirectUrl;

      if (intendedRoute) {
        this.router.navigate([intendedRoute]).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    }
  }

  public wizardReset() {
    this.planWizard.paymentReady$.next(false);
  }

  public finalize() {
    if (this.planWizard.paymentValid$.value) {
      this.redirectToDashboard(true);
    }
  }
}
