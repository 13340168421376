import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../../icon.component';
import { SBHeaderIconComponent } from '../buttons/sb-header-icon.component';
import { SBNavComponent } from '../sb-nav/sb-nav.component';

@Component({
  selector: 'sb-header-hamburger',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, RouterModule, SBHeaderIconComponent, NgClass, SBNavComponent, NgIf, TranslateModule],
  template: ` <div class="h-full px-2">
      <button #button sb-header-icon (click)="toggle()" [icon]="isOpen ? 'times' : 'bars'">
        {{ isOpen ? ('Close' | translate) : ('Open' | translate) }}
      </button>
    </div>
    <div
      class="absolute z-mobileNav mt-px flex h-screen w-72 cursor-default flex-col gap-1 border bg-white p-2 transition-all duration-300 ease-in-out"
      [ngClass]="{ 'left-0': isOpen, '-left-72': !isOpen }"
    >
      <sb-nav [isMobile]="true"></sb-nav>
    </div>
    <!-- Backdrop -->
    <div
      class="absolute left-0 top-full z-mobileNavBackdrop mt-px h-screen w-screen bg-black transition-all duration-700 ease-in-out"
      [ngClass]="{ invisible: !isOpen, 'opacity-80': isOpen, 'opacity-0': !isOpen }"
      (click)="close()"
    ></div>`,
})
export class SBHeaderHamburgerComponent {
  public isOpen = false;

  @ViewChild('button', { read: ElementRef, static: true })
  public buttonRef: ElementRef<HTMLButtonElement>;

  @HostListener('document:click', ['$event'])
  public clickout(event: { target: HTMLInputElement }): void {
    if (!this.isOpen || this.buttonRef.nativeElement.contains(event.target)) {
      return;
    }

    this.close();
  }

  public close(): void {
    this.isOpen = false;
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
