import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SummaryItemComponent } from '@sb/ui';

import { OpenShiftModel } from '../../../../reducers/orm/open-shift/open-shift.model';
import { IconComponent } from '../../icon.component';
import { SidebarHeaderComponent } from '../sidebar-header.component';
import { SidebarRequestItemDirective } from '../sidebar-request-item.directive';
import { TimePipe } from './../../../../pipes/time.pipe';
import { DatePipe } from './../../../../shared/sb-lib/calendar/pipes/date.pipe';

@Component({
  selector: 'open-shifts',
  templateUrl: './open-shifts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TimePipe,
    IconComponent,
    TranslateModule,
    DatePipe,
    SummaryItemComponent,
    SidebarHeaderComponent,
    SidebarRequestItemDirective,
  ],
})
export class OpenShiftsComponent {
  @Input()
  public openShifts: OpenShiftModel[];

  @Output()
  public acceptOpenShiftRequest = new EventEmitter<string>();
  @Output()
  public rejectOpenShiftRequest = new EventEmitter<string>();

  public constructor(private router: Router) {}

  public showOpenShift(occurrenceId) {
    this.router.navigate(['', { outlets: { modal: ['schedule-open-shift', occurrenceId] } }]);
  }
}
