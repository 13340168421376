import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AuthAction } from '../../reducers/auth/auth.action';
import { getAuthLoading, getAuthRefreshedAt } from '../../reducers/auth/auth.service';
import { AppState } from '../../reducers/index';

@Injectable()
export class RefreshAuthInterceptor implements HttpInterceptor {
  public constructor(private store: Store<AppState>) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.body && event.body.meta) {
            this.refreshAuthenticatedUser(event.body.meta);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse && err.error && err.error.meta) {
            this.refreshAuthenticatedUser(err.error.meta);
          }
        },
      ),
    );
  }

  private refreshAuthenticatedUser(meta) {
    if (!meta || !meta['user_refreshed_at']) {
      return;
    }
    const refreshedAtShouldBe = meta['user_refreshed_at'];

    observableCombineLatest(this.store.select(getAuthRefreshedAt), this.store.select(getAuthLoading))
      .pipe(first())
      .subscribe(([authRefreshedAt, loading]) => {
        if (loading || !authRefreshedAt) {
          return;
        }

        if (authRefreshedAt >= refreshedAtShouldBe) {
          return;
        }

        this.store.dispatch(AuthAction.refresh());
      });
  }
}
