import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SentryLoggingService } from '@app/services/sentryLogging.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  public constructor(private injector: Injector) {}

  public handleError(err: any): void {
    console.error(err);

    const sentryLoggingService = this.injector.get(SentryLoggingService);
    if (sentryLoggingService.isInitialized) {
      sentryLoggingService.handleError(err);
    }
  }
}
