<div class="flex h-14 items-center justify-between bg-primary-500 pl-3 font-semibold text-white">
  <div class="sidebar__nav-header--title pr-2.5">{{ 'My Requests' | translate }}</div>
  <div class="sidebar__nav-header--close">
    <button sb-button [color]="'primary'" (click)="close()" class="sb-button-responsive-label">
      {{ 'Close' | translate }}
      <sb-icon iconPositionEnd [name]="'chevron-right'"></sb-icon>
    </button>
  </div>
</div>
<div>
  @if (loading || (noRequests | async)) {
    <div class="sidebar-inline__content">
      <div class="sidebar-inline__content--empty">
        <content-state
          [isLoading]="loading"
          [isEmpty]="true"
          icon="clipboard"
          size="extra-small"
          content="{{ 'No requests have been found' | translate }}"
        ></content-state>
      </div>
    </div>
  } @else {
    <ng-container *subscriptionPlan="planType.BASIC">
      <exchange-requests
        [exchanges]="incomingExchanges | async"
        [title]="'Incoming Exchanges' | translate"
        [context]="exchangeRequestContext.INCOMING"
        [showAvatars]="true"
        [showIndicator]="true"
      />
      <exchange-requests [exchanges]="outgoingExchanges | async" [title]="'Outgoing Exchanges' | translate" />
      <open-shifts [openShifts]="openShifts | async" />
      <absence-request-notifications [absences]="pendingAbsences | async" [isOwnAbsences]="true" />
    </ng-container>
  }
</div>
