<app-content-header class="content-header" [title]="'Files'">
  <ul contentNav>
    <li
      [routerLink]="['/news']"
      routerLinkActive="settings-sidebar__item--active"
      [routerLinkActiveOptions]="{ exact: true }"
      contentNavItem
      icon="calendar"
      [label]="'Messages' | translate"
    ></li>
    <li
      *ngIf="(planType.BASIC | planCheck) && ('Manage account' | permissionCheck)"
      [routerLink]="['/files']"
      routerLinkActive="settings-sidebar__item--active"
      [routerLinkActiveOptions]="{ exact: true }"
      contentNavItem
      icon="calendar"
      [label]="'Files' | translate"
    ></li>
  </ul>
</app-content-header>

<div #contentDiv class="page w-full">
  <div class="page__content">
    <div class="card__no-padding">
      <div class="card-heading">
        <div class="card-heading__title" translate>Company files</div>

        <a
          *ngIf="'Manage account' | permissionCheck"
          class="btn btn-primary flex items-center text-base"
          [routerLink]="['', { outlets: { modal: ['company-file'] } }]"
          translate
        >
          <icon class="si mr-[10px]" svgIcon="sum"></icon>
          Add file
        </a>
      </div>
      <div class="card-table">
        <content-state
          [isEmpty]="!loading && files.length === 0"
          [isLoading]="loading"
          icon="files-o"
          size="small"
          [title]="'No files found' | translate"
          [content]="'Start adding files now' | translate"
          [contentTemplate]="fileTemplate"
          direction="column"
        >
        </content-state>

        <ng-template #fileTemplate>
          <table class="table-border-padding files-list table-uniform-rows">
            <tbody>
              <tr *ngFor="let file of files; trackBy: trackById">
                <td>{{ file.title }}</td>
                <td>{{ file.description }}</td>

                <td class="table-border-padding__actions">
                  <button
                    (click)="download(file)"
                    class="btn btn-clear btn-icon"
                    aria-label="download"
                    bs-tooltip
                    data-title="download"
                  >
                    <icon svgIcon="download" class="si si-download table-border-padding__actions-icon"></icon>
                  </button>
                  <button
                    [routerLink]="['', { outlets: { modal: ['company-file', file.id] } }]"
                    class="btn btn-clear btn-icon"
                    aria-label="edit"
                    bs-tooltip
                    data-title="edit"
                  >
                    <icon svgIcon="edit" class="si si-download table-border-padding__actions-icon"></icon>
                  </button>
                  <action-confirm
                    [sbTooltip]="'Delete' | translate"
                    [sbTooltipPlacement]="'left'"
                    [attr.aria-label]="'Delete' | translate"
                    icon="delete"
                    (onConfirm)="delete(file)"
                  >
                  </action-confirm>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </div>
    </div>
  </div>
</div>
