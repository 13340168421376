import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { defaultAbsencesDateRange } from '@app/reducers/orm/absence/absence.helper';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, IconComponent } from '@sb/ui';
import { combineLatest, map, Observable, combineLatest as observableCombineLatest, take } from 'rxjs';

import { ContentStateComponent } from '../../../../../../src/app/shared/content-state/content-state.component';
import { getAuthenticatedUserId } from '../../../../reducers/auth/auth.service';
import { AppState } from '../../../../reducers/index';
import { EnhancedAbsenceModel } from '../../../../reducers/orm/absence/absence.model';
import {
  AbsenceService,
  getApprovedOpenEndedAbsencesForSupervisor,
  getPendingAbsencesForSupervisor,
} from '../../../../reducers/orm/absence/absence.service';
import { ExchangeModel } from '../../../../reducers/orm/exchange/exchange.model';
import {
  ExchangeService,
  getExchangesForEmployeeApproval,
  getExchangesForSupervisorApproval,
} from '../../../../reducers/orm/exchange/exchange.service';
import { PlanType } from '../../../+reports/shared/subscriptions/subscription.model';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { PermissionDirective } from '../../permission.directive';
import { AbsenceRequestNotificationsComponent } from '../absence-request-notifications.component';
import { ExchangeRequestContext, ExchangeRequestsComponent } from '../exchange-requests.component';
import { SubscriptionPlanDirective } from './../../../../shared/subscription-plan/subscription-plan.directive';

@Component({
  selector: 'supervisor-requests-sidebar',
  templateUrl: './supervisor-requests-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    SubscriptionPlanDirective,
    PermissionDirective,
    AsyncPipe,
    ContentStateComponent,
    AbsenceRequestNotificationsComponent,
    ExchangeRequestsComponent,
    ButtonComponent,
    IconComponent,
  ],
})
export class SupervisorRequestsSidebarComponent implements OnInit {
  public loading = false;
  public noRequests: Observable<boolean>;
  public supervisorApprovalExchanges: Observable<ExchangeModel[]>;
  public employeeApprovalExchanges: Observable<ExchangeModel[]>;
  public pendingAbsenceRequests: Observable<EnhancedAbsenceModel[]>;
  public approvedOpenEndedAbsences: Observable<EnhancedAbsenceModel[]>;
  public planType = PlanType;
  public ExchangeRequestContext = ExchangeRequestContext;

  public constructor(
    private sidebar: SidebarComponent,
    private store: Store<AppState>,
    private exchangeService: ExchangeService,
    private absenceService: AbsenceService,
    private ref: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    this.loading = true;

    void observableCombineLatest([
      this.store.select(getAuthenticatedUserId),
      this.exchangeService.loadAllPending(),
      this.loadPendingAbsences(),
      this.loadApprovedOpenEndedAbsences(),
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => {
          this.loading = false;
          this.ref.markForCheck();
        },
      });

    this.supervisorApprovalExchanges = this.store.select(getExchangesForSupervisorApproval);
    this.employeeApprovalExchanges = this.store.select(getExchangesForEmployeeApproval);
    this.pendingAbsenceRequests = this.store.select(getPendingAbsencesForSupervisor);
    this.approvedOpenEndedAbsences = this.store.select(getApprovedOpenEndedAbsencesForSupervisor);
    this.noRequests = combineLatest([
      this.supervisorApprovalExchanges,
      this.employeeApprovalExchanges,
      this.pendingAbsenceRequests,
      this.approvedOpenEndedAbsences,
    ]).pipe(
      map(
        ([supervisorApprovalExchanges, employeeApprovalExchanges, pendingAbsenceRequests, approvedOpenEndedAbsences]) =>
          supervisorApprovalExchanges.length === 0 &&
          employeeApprovalExchanges.length === 0 &&
          pendingAbsenceRequests.length === 0 &&
          approvedOpenEndedAbsences.length === 0,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  public loadPendingAbsences() {
    const absenceRequest = {
      ...defaultAbsencesDateRange,
      status: 'Pending',
    };

    return this.absenceService.load(absenceRequest);
  }

  public loadApprovedOpenEndedAbsences() {
    const absenceRequest = {
      ...defaultAbsencesDateRange,
      status: 'Approved',
      only_open_ended: true,
    };

    return this.absenceService.load(absenceRequest);
  }

  public close() {
    this.sidebar.close();
  }
}
