import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getWeatherForWidget } from '../../../../../reducers/orm/weather/weather.selector';
import { Subscription } from 'rxjs';
import { AppState } from '../../../../../reducers';
import { addDays } from 'date-fns';
import { WeatherService } from '../../../../../reducers/orm/weather/weather.service';
import { dayList, format } from '../../../../../shared/date.helper';
import { WidgetForecastModel } from '../../../../../reducers/orm/weather/weather.model';

@Component({
  selector: 'my-weather-forecast-widget',
  templateUrl: './my-weather-forecast-widget.component.html',
  styleUrls: ['_my-weather-forecast-widget.component.scss'],
})
export class MyWeatherForecastWidgetComponent implements OnInit, OnDestroy {
  public isLoading = false;
  private dataSubs = new Subscription();

  public dates: string[];

  public departmentForecasts: WidgetForecastModel[] = [];

  private forecastDays = 3;

  constructor(private store: Store<AppState>, private weatherService: WeatherService) {}

  ngOnInit() {
    const currentDate = new Date();
    const maxDate = addDays(new Date(), this.forecastDays);

    this.dates = dayList(currentDate, maxDate);

    const period = {
      minDate: format(currentDate, 'yyyy-MM-dd'),
      maxDate: format(maxDate, 'yyyy-MM-dd'),
    };

    this.weatherService.load(period, true).subscribe();

    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    this.dataSubs.add(
      this.store
        .pipe(select(getWeatherForWidget(new Date(), addDays(new Date(), this.forecastDays))))
        .subscribe((departmentForecasts) => {
          this.departmentForecasts = departmentForecasts;
          this.isLoading = false;
        })
    );
  }

  ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }
}
