<div class="flex h-14 items-center justify-between bg-primary-500 pl-3 font-semibold text-white">
  <div class="sidebar__nav-header--title pr-2.5">{{ 'Requests' | translate }}</div>
  <div class="sidebar__nav-header--close">
    <button sb-button [color]="'primary'" (click)="close()" class="sb-button-responsive-label">
      {{ 'Close' | translate }}
      <sb-icon iconPositionEnd [name]="'chevron-right'"></sb-icon>
    </button>
  </div>
</div>
<div>
  @if (loading || (noRequests | async)) {
    <div class="sidebar-inline__content">
      <div class="sidebar-inline__content--empty">
        <content-state
          [isLoading]="loading"
          [isEmpty]="true"
          icon="clipboard"
          size="extra-small"
          content="{{ 'No requests have been found' | translate }}"
        >
        </content-state>
      </div>
    </div>
  } @else {
    <ng-container *subscriptionPlan="planType.BASIC">
      <exchange-requests
        [exchanges]="supervisorApprovalExchanges | async"
        [title]="'Pending supervisor approval' | translate"
        [context]="ExchangeRequestContext.PENDING_APPROVAL"
        [showAvatars]="true"
        [showIndicator]="true"
      />
      <exchange-requests
        [exchanges]="employeeApprovalExchanges | async"
        [title]="'Pending employee acceptance' | translate"
        [showAvatars]="true"
      />
      <absence-request-notifications
        *permission="'Approve absentee'; user: 'me'; department: 'any'"
        [absences]="pendingAbsenceRequests | async"
      />
      <absence-request-notifications
        *permission="'Approve absentee'; user: 'me'; department: 'any'"
        [absences]="approvedOpenEndedAbsences | async"
        [title]="'Approved ongoing absences' | translate"
      />
    </ng-container>
  }
</div>
