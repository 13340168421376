import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../../../+authenticated/shared/icon.component';
import { SharedModule } from '../../../shared/shared.module';
import { AppConfigureComponent } from './app-configure.component';
import { ContractSyncComponent } from './contract-sync/contract-sync.component';
import { DefaultEmployeeMappingComponent } from './default-employee-mapping/default-employee-mapping.component';
import { EmployeeSyncComponent } from './employee-sync/employee-sync.component';

@NgModule({
  imports: [
    TranslateModule,
    SharedModule,
    IconComponent,
    AppConfigureComponent,
    ContractSyncComponent,
    EmployeeSyncComponent,
    ReactiveFormsModule,
    DefaultEmployeeMappingComponent,
  ],
})
export class AppConfigureModule {
  public static forRoot(): ModuleWithProviders<AppConfigureModule> {
    return {
      ngModule: AppConfigureModule,
    };
  }
}
