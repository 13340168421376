import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { getPageParamsState } from '../../../reducers/page-params-state/page-params.service';
import { Subscription, combineLatest } from 'rxjs';
import { PlanType } from '../../+reports/shared/subscriptions/subscription.model';
import { DialogService } from 'primeng/dynamicdialog';
import {
  LockType,
  planLockDialogConfig,
} from '../../../shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.model';
import { SbPlanLockDialogComponent } from '../../../shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.component';
import { SbPlanDialogService } from '../../../shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.service';
import { hasAtMostSubscriptionPlan } from '../../../shared/subscription-plan/subscription-plan.directive';
import { getAccountSubscription } from '../../../reducers/account/account.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  providers: [DialogService],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Output()
  public itemClicked: EventEmitter<any> = new EventEmitter<any>();

  public scheduleLink: string | any[] = '/schedule/employee/week';
  public timesheetLink: string | any[] = '/timesheet/team/day';

  public planType = PlanType;
  public lockType = LockType;
  public hasAtMostFree: boolean;

  // private pageParamsSubscription: Subscription;
  private dataSubs = new Subscription();

  public constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    public sbPlanDialogService: SbPlanDialogService
  ) {}

  public onClick() {
    this.itemClicked.emit();
  }

  public ngOnInit(): void {
    this.dataSubs.add(
      combineLatest([this.store.select(getPageParamsState), this.store.select(getAccountSubscription)]).subscribe({
        next: ([pageParams, accountSubscription]) => {
          this.hasAtMostFree = hasAtMostSubscriptionPlan(PlanType.FREE, accountSubscription);
          this.scheduleLink = [
            '/schedule',
            pageParams.schedule.mode,
            pageParams.schedule.period,
            { date: pageParams.schedule.date },
          ];
          this.timesheetLink = [
            '/timesheet',
            pageParams.timesheet.mode,
            pageParams.timesheet.period,
            { date: pageParams.timesheet.date },
          ];
        },
      })
    );
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  public openLockModal(lockType: LockType) {
    this.dialogService.open(SbPlanLockDialogComponent, {
      data: {
        lockType: lockType,
      },
      ...planLockDialogConfig,
    });
  }
}
