import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IconComponent } from '../../../icon.component';

@Component({
  selector: 'sb-profile-clock-out-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgClass, TranslateModule, RouterModule, IconComponent],
  template: `
    <a class="flex w-full px-2">
      <div
        [routerLink]="['', { outlets: { modal: ['clock'] } }]"
        class="flex flex-1 px-3.5 py-4 xl:px-1.5 xl:py-2 rounded gap-3 items-center
       text-error-400  bg-error-50 hover:bg-error-100 font-bold text-xl xl:font-semibold xl:text-lg"
      >
        <icon class="w-5 h-5 xl:h-4 xl:w-4" svgIcon="stopwatch-slash"></icon>
        {{ 'Clock-out' | translate }}
      </div>
    </a>
  `,
})
export class SBProfileClockOutItemComponent {}
